export class TrfaAlertsModel {
    updateId:number;
    updateDate: string;      
    note: string;  
    isNotify: boolean;
    name:string;    
    email:string;
    userId:number; 
    isSendMail:boolean;
    fundName:string;
    totalRowCount: string;   
    productId:number;
}

export class Alerts911Model {
    updateId:number;
    rateId:number;
    stateId:number;
    note: string;  
    updateDate: string;      
    rate:string;
    fee:string;
    isNotify: boolean;
    name:string;    
    email:string;
    userId:number; 
    isSendMail:boolean;
    fundName:string;
    totalRowCount: string;   
    productId:number;
}

export class VoipAlertsModel {
    updateId:number;    
    description: string;  
    updateDate: string; 
    isNotify: boolean;
    name:string;    
    email:string;
    userId:number; 
    isSendMail:boolean;
    updateCategoryName:string;
    createdBy: number;
    createdDate: string;
    modifiedBy: number;
    modifiedDate: string;
    createdUser: string;
    modifiedUser: string;
    totalRowCount: string; 
    productId:number;
}

export class WirelessAlertsModel {
    updateId:number;
    rateId:number;
    stateId:number;
    note: string;  
    updateDate: string;      
    rate:string;
    fee:string;
    isNotify: boolean;
    name:string;    
    email:string;
    userId:number; 
    isSendMail:boolean;
    fundName:string;
    totalRowCount: string;   
    productId:number;
    updateCategoryName:string;
    description: string;  
}

export class BriefingModel{
    briefingId:number;
    title:string;
    updateDate:string;
    name:string;
    email:string;
    userId:number;
    totalRowCount:string;
    productId:number;
    isSendMail:boolean;
    description:string;
}

export class IPRModel{
    updateId:number;
    userId:number;
    updateDate:string;
    name:string;
    email:string;
    providerName:string;
    totalRowCount:string;
    isNotify:boolean;
    productId:number;
}

export class CrraAlertsModel {
    updateId:number;
    updateDate: string;      
    updateNote: string;  
    isNotify: boolean;
    name:string;    
    email:string;
    userId:number; 
    isSendMail:boolean;
    fundName:string;
    totalRowCount: string;   
    productId:number;
}

export class IPCSAlertsModel {
    updateId:number;    
    description: string;  
    updateDate: string; 
    isNotify: boolean;
    name:string;    
    email:string;
    userId:number; 
    isSendMail:boolean;
    updateCategoryName:string;
    createdBy: number;
    createdDate: string;
    modifiedBy: number;
    modifiedDate: string;
    createdUser: string;
    modifiedUser: string;
    totalRowCount: string; 
}
