import { throwError as observableThrowError, Observable } from 'rxjs';
import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { CompanyModel } from 'src/Models/CompanyModel';
import { FilterModel } from 'src/Models/filterModel';

@Injectable({
  providedIn: 'root'
})
export class CompanyService {
  AppUrl: string = "";
  filterModel = new FilterModel();

  setAPIUrl() {
    if (sessionStorage.getItem("BaseURL") != null && sessionStorage.getItem("BaseURL") != "") {
      this.AppUrl = sessionStorage.getItem("BaseURL") || '';
    }
  }
  constructor(private _http: HttpClient, @Inject('BASE_URL') baseUrl: string) {
    this.setAPIUrl();
  }

  GetCompanies(TokenKey: string, PageNumber: any, PageSize: any, WhereCondition: any, OrderBy: any, keywordFilter: string, loginUser: any, listOfProduct: string,statusId:string): Observable<any> {
  
    this.setAPIUrl();
    this.filterModel.pageNumber = PageNumber;
    this.filterModel.pageSize = PageSize;
    this.filterModel.whereCondition = WhereCondition;
    this.filterModel.orderBy = OrderBy;
    this.filterModel.keywordFilter = keywordFilter;
    this.filterModel.userID = loginUser;
    this.filterModel.listOfProduct = listOfProduct == "" ? "null" : listOfProduct;
    this.filterModel.statusId = statusId == "" ? "null" : statusId;
    let data = 'api/Companies/v2/GetCompanies';
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.post<any>(this.AppUrl + data, this.filterModel, httpOptions)
      .pipe(catchError(this.handleError));
  }

  addUpdateCompany(company: CompanyModel, TokenKey: string): Observable<any> {
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.post<any>(this.AppUrl + 'api/Companies/AddUpdateCompany', company, httpOptions)
      .pipe(catchError(this.handleError));
  }

  getCompanyFindByCompanyId(TokenKey: string, companyId: number, userId: number, onlyCompany = false) {
    this.setAPIUrl();
    let data = `api/Companies/getCompanyFindByCompanyId/${companyId}/${userId}/${onlyCompany}`;
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.get<any>(this.AppUrl + data, httpOptions)
      .pipe(catchError(this.handleError));
  }

  GetStateFindByCompanyID(TokenKey: string, companyId: number): Observable<any> {
    this.setAPIUrl();
    let fullAPIPath = this.AppUrl + 'api/Companies/GetStateFindByCompanyID/' + companyId;
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.get<any>(fullAPIPath, httpOptions).pipe(catchError(this.handleError));
  }

  GetCompanyAssignedUser(TokenKey: string, companyId: number, productId: number): Observable<any> {
    this.setAPIUrl();
    let fullAPIPath = this.AppUrl + 'api/Companies/GetCompanyAssignedUser/' + companyId + '/' + productId;
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.get<any>(fullAPIPath, httpOptions).pipe(catchError(this.handleError));
  }

  CompanyAssignUserSave(TokenKey: string, data: any): Observable<any> {
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.post<any>(this.AppUrl + 'api/Companies/CompanyAssignUserSave', data, httpOptions)
      .pipe(catchError(this.handleError));
  }

  GetCompanyProduct(TokenKey: string, companyId): Observable<any> {
    this.setAPIUrl();
    let fullAPIPath = this.AppUrl + 'api/Companies/GetCompanyProduct/' + companyId;
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.get<any>(fullAPIPath, httpOptions).pipe(catchError(this.handleError));
  }

  DeleteCompany(TokenKey: string, companyId: number): Observable<any> {
    this.setAPIUrl();
    let fullAPIPath = this.AppUrl + 'api/Companies/DeleteCompany/' + companyId;
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.get<any>(fullAPIPath, httpOptions).pipe(catchError(this.handleError));
  }

  GetCompanyAssignedUserFreeProduct(TokenKey: string, data: any): Observable<any> {   
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.post<any>(this.AppUrl + 'api/Companies/GetCompanyAssignedUserFreeProduct', data, httpOptions)
      .pipe(catchError(this.handleError));
  }

  ExportCompanies(TokenKey: string, PageNumber: any, PageSize: any, WhereCondition: any, OrderBy: any, keywordFilter: string, loginUser: any, listOfProduct: string,statusId:string): Observable<any> {
    
    this.setAPIUrl();
    this.filterModel.pageNumber = PageNumber;
    this.filterModel.pageSize = PageSize;
    this.filterModel.whereCondition = WhereCondition;
    this.filterModel.orderBy = OrderBy;
    this.filterModel.keywordFilter = keywordFilter;
    this.filterModel.userID = loginUser;
    this.filterModel.listOfProduct = listOfProduct == "" ? "null" : listOfProduct;   
    this.filterModel.statusId = statusId == "" ? "null" : statusId;
    let data = 'api/Companies/v2/ExportCompanies';
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.post<any>(this.AppUrl + data, this.filterModel, httpOptions)
      .pipe(catchError(this.handleError));
  }

  ExportCompaniesProducts(TokenKey: string): Observable<any> {
    this.setAPIUrl();
    let fullAPIPath = this.AppUrl + 'api/Companies/ExportCompaniesProducts';
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.get<any>(fullAPIPath, httpOptions).pipe(catchError(this.handleError));
  }

  private handleError(error: Response | any) {
    console.error(error.message || error);
    return observableThrowError(error.status)
  }

  GetCompanybyUserId(TokenKey: string, userId: number): Observable<any> {
    this.setAPIUrl();
    let fullAPIPath = this.AppUrl + 'api/Companies/GetCompanybyUserId/' + userId;
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.get<any>(fullAPIPath, httpOptions).pipe(catchError(this.handleError));
  }

  AddCompanyFromFront(companyName: string,createdBy:number, TokenKey: string): Observable<any> {

    this.setAPIUrl();
    let data = `api/Companies/AddCompanyFromFront/${companyName}/${createdBy}`;
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.get<any>(this.AppUrl + data, httpOptions)
      .pipe(catchError(this.handleError));
  }

  GetTypeOfCompany(TokenKey: string): Observable<any> {
    this.setAPIUrl();
    let fullAPIPath = this.AppUrl + 'api/Companies/GetTypeOfCompany';
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.get<any>(fullAPIPath, httpOptions).pipe(catchError(this.handleError));
  }
}
