export class UpdatesPageModel {
  pageIndex: string;
  pageSize: string;
  whereCondition: string;
  orderBy: string;
  keywordFilter: string;
  fromupdatesDateFilter: string;
  toupdatesDateFilter: string;
  userID: string;
  unreadonly: string;
  updatetype: string;
  productId: string;
}

export class IPCSUpdatesPageModel {
  pageIndex: string;
  pageSize: string;
  whereCondition: string;
  orderBy: string;
  keywordFilter: string;
  fromupdatesDateFilter: string;
  toupdatesDateFilter: string;
  userID: string;
  unreadonly: string;
  updatetype: string;
}
