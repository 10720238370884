import { Component, HostListener, OnInit } from '@angular/core';
import { CheckAuth } from 'src/Services/utility.service';
import { UserModel } from '../../../Models/usersModel';
import { UsersService } from '../../../Services/users.service';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { StateMasterService } from 'src/Services/state-master.service';
import { StateMasterModel } from 'src/Models/stateMasterModel';
import { AdminUserType } from 'src/Models/Common';
import { RouterExtService } from 'src/Services/RouterExtService';

@Component({
  selector: 'app-inteserra-users',
  templateUrl: './inteserra-users.component.html',
  styleUrls: ['./inteserra-users.component.css']
})
export class InteserraUsersComponent implements OnInit {
  tokenKey: string = '';
  listOfUser: UserModel[];
  statusCode: any;
  PageNumber: number;
  PageSize: number;
  WhereCondition: string;
  OrderBy: string;
  totalRecordCount: number;

  isAscending: boolean;

  upDowUserNameCSS: string = '';
  upDowFirstNameCSS: string = '';
  upDowEmailCSS: string = '';
  upDowCompanyNameCSS: string = '';
  upDowPhoneCSS: string = '';
  upDowIsActiveCSS: string = '';
  upDowCreatedDateCSS: string = '';
  upDowStateNameCSS: string = '';
  upDowModifiedDateCSS: string = '';
  upDowadminUserNameCSS: string = '';

  listOfSubscription: StateMasterModel[];
  subscriptionCount: number = -1;
  isloading: boolean = false;
  keywordFilter: string = '';
  SelectedTool: string;
  loginUserId: number = 0;
  openMenuIndex: number = -1;

  constructor(private router: Router, private checkAuth: CheckAuth, private userService: UsersService
    , private titleService: Title, private stateServices: StateMasterService
    , private routerService: RouterExtService) {
    this.tokenExpireCalculation(sessionStorage.getItem('appStartTime'), sessionStorage.getItem('tokenExpiryValue'));
    this.tokenKey = sessionStorage.getItem('tokenValue');
    this.listOfUser = [];
    this.PageNumber = 1;
    this.PageSize = 10;
    this.WhereCondition = 'null';
    this.OrderBy = 'UserId DESC';
    this.totalRecordCount = -1;
    this.isAscending = true;
    this.listOfSubscription = [];
    this.subscriptionCount = -1;
    this.SelectedTool = sessionStorage.getItem('UserLoginTool');
    this.titleService.setTitle('Admins');
    this.loginUserId = +sessionStorage.getItem('AdminUserId');


    let toolName = this.SelectedTool == 'actionitem' ? 'trac-it' : this.SelectedTool;
    if (AdminUserType.systemuser == parseInt(sessionStorage.getItem('adminTypeId')))
      this.router.navigate(['admin/' + toolName + '/dashboard'])
  }

  tokenExpireCalculation(startDate: string, tokenExpiry: string) {
    this.checkAuth.tokenExpireCalculation(startDate, tokenExpiry);
  }
  ngOnInit() {
    this.keywordFilter = 'null';
    this.WhereCondition = ' UserTypeId = 2';
    let toolName = this.SelectedTool == 'actionitem' ? 'trac-it' : this.SelectedTool;
    if (this.routerService.getPreviousUrl().includes('admin/' + toolName + '/user/manage')) {
      this.setSaveFilter();
    }
    else {
      this.clearSession();
    }
    this.GetListOfUser();
  }

  GetListOfUser() {
    this.isloading = true;
    this.userService.GetUsersV2(this.tokenKey, this.PageNumber, this.PageSize, this.WhereCondition, this.OrderBy, this.keywordFilter, this.loginUserId, "", "", "","").subscribe(
      data => {
        this.isloading = false;
        this.listOfUser = data['usersModel'];
        if (this.listOfUser != null) {
          if (this.listOfUser.length > 0) {
            this.totalRecordCount = data['usersModel'][0].totalRecordCount;
          }
          else {
            this.totalRecordCount = 0;
          }
        }
      },
      error => {
        this.statusCode = error
        this.isloading = false;
      }
    );
  }

  sort(sortBy: any) {
    this.upDowUserNameCSS = '';
    this.upDowFirstNameCSS = '';
    this.upDowEmailCSS = '';
    this.upDowCompanyNameCSS = '';
    this.upDowPhoneCSS = '';
    this.upDowIsActiveCSS = '';
    this.upDowCreatedDateCSS = '';
    this.upDowStateNameCSS = '';
    this.upDowModifiedDateCSS = '';
    this.upDowadminUserNameCSS = '';

    if (!this.isAscending) {
      this.isAscending = true;
      this.OrderBy = sortBy + ' DESC';

      this.setDescCSS(sortBy);
    }
    else {
      this.isAscending = false;
      this.OrderBy = sortBy;

      this.setAscCSS(sortBy);
    }
    this.GetListOfUser();
  }
  editUser(userId: any) {
    this.saveSession(); // To Store All filtered and other data to restore once back.
    let toolName = this.SelectedTool == 'actionitem' ? 'trac-it' : this.SelectedTool;
    this.router.navigate(['admin/' + toolName + '/user/manage/' + userId]);
  }

  pageChanged($event: any) {
    this.PageNumber = parseInt($event.page);
    this.GetListOfUser();
  }

  addUser() {
    this.saveSession(); // To Store All filtered and other data to restore once back.
    let toolName = this.SelectedTool == 'actionitem' ? 'trac-it' : this.SelectedTool;
    this.router.navigate(['admin/' + toolName + '/user/manage']);
  }

  getSubscriptionState(clientId: any) {
    this.stateServices.GetStateMasterFindByClientId(this.tokenKey, clientId, 0).subscribe(
      data => {
        this.listOfSubscription = data['state'];
        if (this.listOfSubscription != null) {
          if (this.listOfSubscription.length > 0) {
            this.subscriptionCount = this.listOfSubscription.length;
          }
          else {
            this.subscriptionCount = 0;
          }
        }
        else {
          this.subscriptionCount = 0;
        }
      },
      error => { this.statusCode = error }
    );
  }

  keywordFilters($event) {
    this.PageNumber = 1;
    if ($event.target.value != "" && $event.target.value != null)
      this.keywordFilter = $event.target.value;
    else
      this.keywordFilter = 'null';

    this.GetListOfUser();
  }

  saveSession() {
    sessionStorage.setItem('saveFilterIsAscending', this.isAscending ? 'true' : 'false');
    sessionStorage.setItem('saveFilterPageNo', this.PageNumber.toString());
    sessionStorage.setItem('saveFilterOrderBy', this.OrderBy);
    sessionStorage.setItem('saveFilterWhereCondition', this.WhereCondition);
    sessionStorage.setItem('saveFilterKeywordValue', this.keywordFilter);
  }

  clearSession() {
    sessionStorage.removeItem('saveFilterIsAscending');
    sessionStorage.removeItem('saveFilterPageNo');
    sessionStorage.removeItem('saveFilterOrderBy');
    sessionStorage.removeItem('saveFilterWhereCondition');
    sessionStorage.removeItem('saveFilterKeywordValue');
  }

  setSaveFilter() {
    if (sessionStorage.getItem('saveFilterPageNo') != null && sessionStorage.getItem('saveFilterPageNo') != '' && sessionStorage.getItem('saveFilterPageNo') != 'undefined') {
      this.PageNumber = +sessionStorage.getItem('saveFilterPageNo');
    }

    if (sessionStorage.getItem('saveFilterOrderBy') != null && sessionStorage.getItem('saveFilterOrderBy') != '' && sessionStorage.getItem('saveFilterOrderBy') != 'undefined') {
      this.OrderBy = sessionStorage.getItem('saveFilterOrderBy');
    }

    if (sessionStorage.getItem('saveFilterWhereCondition') != null && sessionStorage.getItem('saveFilterWhereCondition') != '' && sessionStorage.getItem('saveFilterWhereCondition') != 'undefined') {
      this.WhereCondition = sessionStorage.getItem('saveFilterWhereCondition');
    }

    if (sessionStorage.getItem('saveFilterKeywordValue') != null && sessionStorage.getItem('saveFilterKeywordValue') != '' && sessionStorage.getItem('saveFilterKeywordValue') != 'undefined') {
      this.keywordFilter = sessionStorage.getItem('saveFilterKeywordValue');
    }

    if (sessionStorage.getItem('saveFilterIsAscending') != null && sessionStorage.getItem('saveFilterIsAscending') != '' && sessionStorage.getItem('saveFilterIsAscending') != 'undefined') {
      this.isAscending = sessionStorage.getItem('saveFilterIsAscending') == 'false' ? false : true;
      if (this.isAscending) {
        this.setDescCSS(this.OrderBy.replace('DESC', '').trim());
      } else {
        this.setAscCSS(this.OrderBy.replace('DESC', '').trim());
      }
    }
    this.clearSession();
  }

  setDescCSS(sortBy) {
    if (sortBy === "userName") {
      this.upDowUserNameCSS = 'fa fa-arrow-down';
    }
    else if (sortBy === "firstName") {
      this.upDowFirstNameCSS = 'fa fa-arrow-down';
    }
    else if (sortBy === "email") {
      this.upDowEmailCSS = 'fa fa-arrow-down';
    }
    else if (sortBy === "companyName") {
      this.upDowCompanyNameCSS = 'fa fa-arrow-down';
    }
    else if (sortBy === "phone") {
      this.upDowPhoneCSS = 'fa fa-arrow-down';
    }
    else if (sortBy === "isActive") {
      this.upDowIsActiveCSS = 'fa fa-arrow-down';
    }
    else if (sortBy === "createdDate") {
      this.upDowCreatedDateCSS = 'fa fa-arrow-down';
    }
    else if (sortBy === "StateName") {
      this.upDowStateNameCSS = 'fa fa-arrow-down';
    }
    else if (sortBy === "modifiedDate") {
      this.upDowModifiedDateCSS = 'fa fa-arrow-down';
    }
    else if (sortBy === "AdminTypeId") {
      this.upDowadminUserNameCSS = 'fa fa-arrow-down';
    }
  }

  setAscCSS(sortBy) {
    if (sortBy === "userName") {
      this.upDowUserNameCSS = 'fa fa-arrow-up';
    }
    else if (sortBy === "firstName") {
      this.upDowFirstNameCSS = 'fa fa-arrow-up';
    }
    else if (sortBy === "email") {
      this.upDowEmailCSS = 'fa fa-arrow-up';
    }
    else if (sortBy === "companyName") {
      this.upDowCompanyNameCSS = 'fa fa-arrow-up';
    }
    else if (sortBy === "phone") {
      this.upDowPhoneCSS = 'fa fa-arrow-up';
    }
    else if (sortBy === "isActive") {
      this.upDowIsActiveCSS = 'fa fa-arrow-up';
    }
    else if (sortBy === "createdDate") {
      this.upDowCreatedDateCSS = 'fa fa-arrow-up';
    }
    else if (sortBy === "StateName") {
      this.upDowStateNameCSS = 'fa fa-arrow-up';
    }
    else if (sortBy === "modifiedDate") {
      this.upDowModifiedDateCSS = 'fa fa-arrow-up';
    }
    else if (sortBy === "AdminTypeId") {
      this.upDowadminUserNameCSS = 'fa fa-arrow-up';
    }
  }

  toggleMenu(index: number) {
    this.openMenuIndex = this.openMenuIndex === index ? -1 : index; // Toggle the menu
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent) {
    const target = event.target as HTMLElement;
    if (!target.closest('.kebab-menu')) {
      this.openMenuIndex = null;
    }
  }

}
