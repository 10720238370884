import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-state-fund-preview',
  templateUrl: './state-fund-preview.component.html',
  styleUrls: ['./state-fund-preview.component.css']
})
export class IPCSStateFundPreviewComponent implements OnInit {
  SelectedTool: string;
  constructor(private router: Router) {
    this.SelectedTool = sessionStorage.getItem('UserLoginTool');
  }
  ngOnInit() {
  }

  backNavigation() {
    let stateId = sessionStorage.getItem('StateSetupManageRedirection');
    let URL = '';
    if (sessionStorage.getItem('isClickOnState') != 'true') {      
      sessionStorage.removeItem('StateSetupManageRedirection');
      URL = 'admin/' + this.SelectedTool + '/state/setup/manage/' + stateId;
    }
    else {
      sessionStorage.removeItem('isClickOnState')
      URL = 'admin/' + this.SelectedTool + '/states';
    }
    this.router.navigate([URL]);
  }
}
