import { Component, OnInit, ViewChild, ElementRef, HostListener } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { UpdatesService } from 'src/Services/updatesService';
import { CheckAuth } from 'src/Services/utility.service';
import { AppConfig } from 'src/Services/app.config';
import { AdminUserType, ToolEnum } from 'src/Models/Common';
import { UpdatesModel } from 'src/Models/updatesModel';

@Component({
  selector: 'app-updates',
  templateUrl: './updates.component.html',
  styleUrls: ['./updates.component.css']
})
export class IPCSUpdatesComponent implements OnInit {
  userID: number = 0;
  totalPageSize: number = 0;
  pageSize: number = 0;
  pageIndex: number = 0;
  orderBy: string = 'null';
  keywordFilter: string = '';
  whereCondition: string = 'null';
  systemUserCondition: string = 'null';

  isActionRequired: boolean = false;
  isAscending: boolean;
  statusCode: number = 0;
  appModel: any;

  upDowUpdateCategoryNameCSS: string = '';
  upDowStateCSS: string = '';
  upDowUpdateTypeCSS: string = '';
  upDowCreatedByCSS: string = '';
  upDowCreatedDateCSS: string = '';
  upDowModifiedByCSS: string = '';
  upDowModifiedDateCSS: string = '';
  upDowCompanyNameCSS: string = '';
  upDowActionChargesCSS: string = '';
  upDowIsActionCSS: string = '';

  isloading: boolean = false;
  tokenKey: string = '';
  updatesModel: UpdatesModel[] = [];
  isRecords: boolean = false;
  isShowprogressSpinner: boolean;
  blockedDocument: boolean = false;
  totalRecordsCount: number = -1;
  deleteupdateId: number = 0;
  SelectedTool: string;
  productId: number = 1;
  openMenuIndex: number = -1;

  @ViewChild('deletecloseBtn', { static: false }) deletecloseBtn: ElementRef;
  constructor(private updatesService: UpdatesService, private toastr: ToastrService
    , private titleService: Title, private checkAuth: CheckAuth
    , private router: Router) {
    this.tokenExpireCalculation(sessionStorage.getItem('appStartTime'), sessionStorage.getItem('tokenExpiryValue'));
    this.userID = +sessionStorage.getItem('AdminUserId');
    this.appModel = AppConfig.settings.ConnectionStrings;
    this.SelectedTool = sessionStorage.getItem('UserLoginTool');
    this.productId = ToolEnum[this.SelectedTool];
    this.titleService.setTitle('Updates');
  }

  tokenExpireCalculation(startDate: string, tokenExpiry: string) {
    this.checkAuth.tokenExpireCalculation(startDate, tokenExpiry);
  }

  ngOnInit() {
    this.movetotop();
    this.pageSize = this.appModel.PageSize;
    this.totalPageSize = 1;
    this.pageIndex = 1;
    this.keywordFilter = 'null';
    this.whereCondition = 'null';
    this.orderBy = 'ISNULL(ModifiedDate,CreatedDate) DESC';    
    if (AdminUserType.systemadmin == parseInt(sessionStorage.getItem('adminTypeId')))
      this.systemUserCondition = 'null'
    else
      this.systemUserCondition = '  (ISNULL(CompanyId,0) = 0 Or CompanyId IN (Select CompanyId From [SystemUserCompany] Where [SystemUserCompany].UserId = ' + this.userID + '))';

    this.GetUpdates();
  }

  GetUpdates(): void {
    this.isloading = true;
    this.tokenKey = sessionStorage.getItem('tokenValue');
    this.updatesModel = [];
    this.updatesService.GetIPCSUpdatesFilterByPage(this.tokenKey, this.pageIndex.toString(), this.pageSize.toString(), this.whereCondition, this.orderBy, this.keywordFilter, this.isActionRequired, this.systemUserCondition).subscribe(
      data => {
        this.isloading = false;
        if (data["status"] == "Success") {
          this.updatesModel = data["updates"];
          if (this.updatesModel.length > 0) {
            this.isRecords = true;
            this.totalPageSize = parseInt(this.updatesModel[0].totalRecordCount);
            this.totalRecordsCount = this.updatesModel.length;
          }
          else {
            this.totalRecordsCount = 0;
            this.isRecords = false;
          }
        }
        else {
          this.showMessage('There is an issue while fetching data. Please try again.', 'Error', 'error');
        }
      },
      error => {
        this.statusCode = error
      },
    );
  }

  editupdate(updateId) {
    this.router.navigate(['/admin/' + this.SelectedTool + '/updates/manage/' + updateId]);
  }

  addnewupdate() {
    this.router.navigate(['/admin/' + this.SelectedTool + '/updates/manage']);
  }

  setDeleteupdateId(updateId: any) {
    this.deleteupdateId = updateId;
  }

  DeleteUpdate() {
    this.blockDocument();
    this.updatesService.DeleteIPCSupdates(this.tokenKey, this.deleteupdateId)
      .subscribe(data => {
        if (data["status"] == "Success") {
          this.showMessage('Deleted successfully', 'Deleted', 'success');
          this.deletecloseBtn.nativeElement.click();
          this.GetUpdates();
        }
        else {
          this.showMessage(data["status"], 'Error', 'error');
        }
        this.unblockDocument();
      }, error => {
        this.showMessage(error, 'Error', 'error');
        this.unblockDocument();
      })
  }

  ActiveInactiveUpdates(updateId: any, isactive: any) {
    this.blockDocument();
    this.updatesService.ActiveinactiveIPCSupdates(this.tokenKey, updateId, isactive)
      .subscribe(data => {
        if (data["status"] == "Success") {
          if (isactive) {
            this.showMessage('Activated successfully', 'Active', 'success');
          }
          else {
            this.showMessage('Inactivated successfully', 'Inactive', 'success');
          }
          this.GetUpdates();
        }
        else {
          this.showMessage(data["status"], 'Error', 'error');
        }
        this.unblockDocument();
      }, error => {
        this.showMessage(error, 'Error', 'error');
        this.unblockDocument();
      })
  }

  sort(sortBy: string) {
    this.upDowUpdateCategoryNameCSS = '';
    this.upDowStateCSS = '';
    this.upDowUpdateTypeCSS = '';
    this.upDowCreatedByCSS = '';
    this.upDowCreatedDateCSS = '';
    this.upDowModifiedByCSS = '';
    this.upDowModifiedDateCSS = '';
    this.upDowCompanyNameCSS = '';
    this.upDowActionChargesCSS = '';
    this.upDowIsActionCSS = '';

    if (!this.isAscending) {
      this.isAscending = true;
      this.orderBy = sortBy + ' DESC';

      if (sortBy === "UpdateCategoryName") {
        this.upDowUpdateCategoryNameCSS = 'fa fa-arrow-down';
      }
      else if (sortBy === "StateShortName") {
        this.upDowStateCSS = 'fa fa-arrow-down';
      }
      else if (sortBy === "UpdateType") {
        this.upDowUpdateTypeCSS = 'fa fa-arrow-down';
      }
      else if (sortBy === "CreatedBy") {
        this.upDowCreatedByCSS = 'fa fa-arrow-down';
      }
      else if (sortBy === "CreatedDate") {
        this.upDowCreatedDateCSS = 'fa fa-arrow-down';
      }
      else if (sortBy === "ModifiedBy") {
        this.upDowModifiedByCSS = 'fa fa-arrow-down';
      }
      else if (sortBy === "ModifiedDate") {
        this.upDowModifiedDateCSS = 'fa fa-arrow-down';
      }
      else if (sortBy === "CompanyName") {
        this.upDowCompanyNameCSS = 'fa fa-arrow-down';
      }
      else if (sortBy === "ActionCharges") {
        this.upDowActionChargesCSS = 'fa fa-arrow-down';
      }
      else if (sortBy === "IsAction") {
        this.upDowIsActionCSS = 'fa fa-arrow-down';
      }
    }
    else {
      this.isAscending = false;
      this.orderBy = sortBy;

      if (sortBy === "UpdateCategoryName") {
        this.upDowUpdateCategoryNameCSS = 'fa fa-arrow-up';
      }
      else if (sortBy === "StateShortName") {
        this.upDowStateCSS = 'fa fa-arrow-up';
      }
      else if (sortBy === "UpdateType") {
        this.upDowUpdateTypeCSS = 'fa fa-arrow-up';
      }
      else if (sortBy === "CreatedBy") {
        this.upDowCreatedByCSS = 'fa fa-arrow-up';
      }
      else if (sortBy === "CreatedDate") {
        this.upDowCreatedDateCSS = 'fa fa-arrow-up';
      }
      else if (sortBy === "ModifiedBy") {
        this.upDowModifiedByCSS = 'fa fa-arrow-up';
      }
      else if (sortBy === "ModifiedDate") {
        this.upDowModifiedDateCSS = 'fa fa-arrow-up';
      }
      else if (sortBy === "CompanyName") {
        this.upDowCompanyNameCSS = 'fa fa-arrow-up';
      }
      else if (sortBy === "ActionCharges") {
        this.upDowActionChargesCSS = 'fa fa-arrow-up';
      }
      else if (sortBy === "IsAction") {
        this.upDowIsActionCSS = 'fa fa-arrow-up';
      }
    }
    this.GetUpdates();
  }

  IsActionRequired($event) {
    this.isActionRequired = $event.target.checked;
    this.GetUpdates();
  }

  pageChanged($event) {
    this.pageIndex = parseInt($event.page);
    this.GetUpdates();
  }

  blockDocument() {
    this.isShowprogressSpinner = true;
    this.blockedDocument = true;
  }

  unblockDocument() {
    this.isShowprogressSpinner = false;
    this.blockedDocument = false;
  }

  showMessage(msg: string, msgTitle: string, msgType: string) {
    if (msgType == 'success') {
      setTimeout(() => this.toastr.success(msg, msgTitle));
    }
    else if (msgType == 'error') {
      setTimeout(() => this.toastr.error(msg, msgTitle));
    }
  }

  movetotop() {
    let scrollToTop = window.setInterval(() => {
      let pos = window.pageYOffset;
      if (pos > 0) {
        window.scrollTo(0, pos - 20); // how far to scroll on each step
      } else {
        window.clearInterval(scrollToTop);
      }
    }, 1);
  }

  keywordFilters($event) {
    this.pageIndex = 1;
    if ($event.target.value != "" && $event.target.value != null)
      this.keywordFilter = $event.target.value;
    else
      this.keywordFilter = 'null';

    this.GetUpdates();
  }

  toggleMenu(index: number) {
    this.openMenuIndex = this.openMenuIndex === index ? -1 : index; // Toggle the menu
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent) {
    const target = event.target as HTMLElement;
    if (!target.closest('.kebab-menu')) {
      this.openMenuIndex = null;
    }
  }
}
