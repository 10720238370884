import { Component, HostListener, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute,Params, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ToolEnum } from 'src/Models/Common';
import { FilterModel } from 'src/Models/filterModel';
import { IPCSAlertsModel } from 'src/Models/TrfaAlertsModel';
import { TrfaAlertService } from 'src/Services/alert.service';
import { CheckAuth } from 'src/Services/utility.service';

@Component({
  selector: 'app-user-ipcs-alerts',
  templateUrl: './user-ipcs-alerts.component.html',
  styleUrls: ['./user-ipcs-alerts.component.css']
})
export class UserIPCSAlertsComponent implements OnInit {

  tokenKey: string = '';
  isloading: boolean = false;
  filterModel = new FilterModel();
  IPCSAlertsModel1: IPCSAlertsModel[]=[];
  totalRecordCount1: number = -1;
  updateId:number;
  userId:number = 0;
  keywordFilter: string=" ";
  pageNumber:number;
  pageSize:number;
  updateCategoryName: string = '';
  orderBy:string;
  upDownEmailCSS: string = '';
  upDownNameCSS: string = '';
  isAscending: boolean;
  productId: number;
  openMenuIndex: number = -1;

   constructor(private activedRoute: ActivatedRoute,private toastr: ToastrService, private router: Router, private checkAuth: CheckAuth,public trfaAlertServices:TrfaAlertService,private titleService:Title) { 
    this.tokenKey = sessionStorage.getItem('tokenValue');
    this.tokenExpireCalculation(sessionStorage.getItem('appStartTime'), sessionStorage.getItem('tokenExpiryValue'));
    this.productId = ToolEnum.ipcs;
    this.titleService.setTitle('IPCS Alert Users');
  }

  tokenExpireCalculation(startDate: string, tokenExpiry: string) {
    this.checkAuth.tokenExpireCalculation(startDate, tokenExpiry);
  }

  ngOnInit() {
    this.IPCSAlertsModel1=[];
    this.keywordFilter="";
    this.pageNumber = 1;
    this.pageSize = 10;
    // this.filteModel.whereCondition = '';
    // //this.filteModel.productId = 4;
    this.orderBy = 'UpdateId ASC';    
    this.activedRoute.params.subscribe((params: Params) => {

      if (params['id']) {
        if (params['id'] != null) {
          this.updateId = params['id'];
          this.getUserList();
        }
      }
      else {
        this.updateId = 0;
      }
    });
  }

  keywordFilters($event) {
    this.keywordFilter = $event.target.value;
    //this.filteModel.pageNumber = '1';
    if ($event.target.value != "" && $event.target.value != null) {
      this.keywordFilter = $event.target.value;
    }
    else { this.keywordFilter = ""; }

    this.getUserList();
  }

  getUserList() {

    this.isloading = true;
    if (this.keywordFilter == "null" || this.keywordFilter == "")
    { this.keywordFilter = "null";}
    this.trfaAlertServices.GetIPCSAlertsByIds(this.tokenKey,this.updateId,this.pageNumber,this.pageSize,this.keywordFilter,this.userId,this.orderBy).subscribe(data => {
      if (data['status'] == 'Success') {
        this.isloading = false;
        this.IPCSAlertsModel1 = data['ipcsAlert'];        
        if (this.IPCSAlertsModel1 != null) {
          if (this.IPCSAlertsModel1.length > 0) {
            this.totalRecordCount1 = +this.IPCSAlertsModel1[0].totalRowCount;
          }
          else {
            this.totalRecordCount1 = 0;
          }
        }
        this.updateCategoryName = this.IPCSAlertsModel1[0].updateCategoryName;
      }
      else {
        this.showMessage(data['status'], 'Error', 'error');
      }

    },
      error => {
        this.showMessage(error, 'Error', 'error');
      }
    );
  }

  showMessage(msg: string, msgTitle: string, msgType: string) {
    if (msgType == 'success') {
      setTimeout(() => this.toastr.success(msg, msgTitle));
    }
    else if (msgType == 'error') {
      setTimeout(() => this.toastr.error(msg, msgTitle));
    }
  }

  redirectToEditView(updateId:any,userId:any) {
    this.router.navigate(['/admin/ipcs/ipcs-Alerts/manage/' + updateId + '/' + userId]);
  }
  
  pageChanged($event: any) {
    this.pageNumber = parseInt($event.page);
    this.getUserList();
  }

  sort(sortBy: any) {
    if (!this.isAscending) {
      this.isAscending = true;
      this.orderBy = sortBy + ' DESC';
      this.setDescCSS(sortBy);
    }
    else {
      this.isAscending = false;
      this.orderBy = sortBy;

      this.setAscCSS(sortBy);
    }
    this.getUserList();
  }

  setDescCSS(sortBy) {
    if (sortBy === "email") {
      this.upDownEmailCSS = 'fa fa-arrow-down';
      this.upDownNameCSS = '';
    }
    else if (sortBy === "name") {
      this.upDownEmailCSS = '';
      this.upDownNameCSS = 'fa fa-arrow-down';
    }
  }

  setAscCSS(sortBy) {
    if (sortBy === "email") {
      this.upDownEmailCSS = 'fa fa-arrow-up';
      this.upDownNameCSS = '';
    }
    else if (sortBy === "name") {
      this.upDownEmailCSS = '';
      this.upDownNameCSS = 'fa fa-arrow-up';
    }
  }

  toggleMenu(index: number) {
    this.openMenuIndex = this.openMenuIndex === index ? -1 : index; // Toggle the menu
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent) {
    const target = event.target as HTMLElement;
    if (!target.closest('.kebab-menu')) {
      this.openMenuIndex = null;
    }
  }
}
