import { Component, HostListener, Inject, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { TabsetComponent } from 'ngx-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { AdminUserType, ToolEnum } from 'src/Models/Common';
import { FilterModel } from 'src/Models/filterModel';
import { CompanyService } from 'src/Services/company.service';
import { ProductsService } from 'src/Services/products.service';
import { RouterExtService } from 'src/Services/RouterExtService';
import { StateMasterService } from 'src/Services/state-master.service';
import { UpdatesService } from 'src/Services/updatesService';
import { CheckAuth } from 'src/Services/utility.service';
import * as FileSaver from 'file-saver';
import { I360Service } from 'src/Services/i360Service';
import { AdminLayoutComponent } from 'src/app/Layout/admin-layout/admin-layout.component';
import { ClientCompanyModel } from 'src/Models/ClientCompanyModel';

@Component({
  selector: 'app-company',
  templateUrl: './company.component.html',
  styleUrls: ['./company.component.css']
})
export class CompanyComponent implements OnInit {

  @ViewChild('statesTabs', { static: false }) statesTabs: TabsetComponent;

  tokenKey: string = '';
  //listOfCompany:any=[];
  listOfCompany: ClientCompanyModel[];

  statusCode: any;
  PageNumber: number;
  PageSize: number;
  WhereCondition: string;
  OrderBy: string;
  totalRecordCount: number;

  isAscending: boolean;

  upDowCompanyCSS: string = '';
  upDowIsActiveCSS: string = '';
  upDowCreatedDateCSS: string = '';
  upDowModifiedDateCSS: string = '';
  upDowSFAccountCSS: string = '';
  upDowDBACSS: string = '';
  isloading: boolean = false;
  keywordFilter: string = '';
  SelectedTool: string;
  stateSubscription: any;

  isGeneralAlertSubscription: boolean = false;
  loginUserId: number = 0;

  isUserSystemAdmin: boolean = false;

  // Product List---------------
  dropdownListProduct = [];
  selectedItemsProduct = [];
  dropdownSettingsProduct = {};
  productFilter: string = '';
  listOfProductID: string[] = [];
  // Product List---------------
  listOfProduct?: string;

  productId: number = 1;
  devideRowCount = 0;
  deleteCompanyId = 0;
  filterModel = new FilterModel();

  blockedDocument = false;
  isShowprogressSpinner = false;

  dropdownSettingsStatus = {};
  selectedItemsStatus = [];
  listOfStatus = [];
  statusId: string = ' ';
  moduleList: any[] = [{ itemName: 'Yes', id: '1' }, { itemName: 'No', id: '0' }];
  openMenuIndex: number = -1;

  constructor(@Inject(Window) private win: Window, private router: Router, private checkAuth: CheckAuth, private companyService: CompanyService
    , private titleService: Title, private stateServices: StateMasterService
    , private routerService: RouterExtService
    , private productsService: ProductsService
    , private toastr: ToastrService
    , private i360service: I360Service
    , private updatesService: UpdatesService,private adminLayout:AdminLayoutComponent) {
    this.tokenExpireCalculation(sessionStorage.getItem('appStartTime'), sessionStorage.getItem('tokenExpiryValue'));
    this.tokenKey = sessionStorage.getItem('tokenValue');

    this.listOfCompany = [];
    this.PageNumber = 1;
    this.PageSize = 10;
    this.WhereCondition = 'null';
    this.OrderBy = 'CompanyId DESC';
    this.totalRecordCount = -1;
    this.isAscending = true;
    this.SelectedTool = sessionStorage.getItem('UserLoginTool');
    this.titleService.setTitle('Companies');
    this.loginUserId = +sessionStorage.getItem('AdminUserId');
    this.productId = ToolEnum[this.SelectedTool];
    this.selectedItemsStatus.push({ "id": 1, "itemName": "Yes" })
    this.listOfStatus.push(1);
    this.statusId = ' ';
    if (this.listOfStatus.length > 0) {
      this.statusId = '1'
    }
  }


  tokenExpireCalculation(startDate: string, tokenExpiry: string) {
    this.checkAuth.tokenExpireCalculation(startDate, tokenExpiry);
  }
  ngOnInit() {
    this.keywordFilter = 'null';
    if (AdminUserType.systemadmin == parseInt(sessionStorage.getItem('adminTypeId'))) {
      this.isUserSystemAdmin = true;
    }
    else {
      this.WhereCondition = ' (CompanyId IN (Select CompanyId From [SystemUserCompany] Where [SystemUserCompany].UserId = ' + this.loginUserId + ') OR CompanyId In (Select CompanyId From UserMaster Where UserId = ' + this.loginUserId + '))';
    }
    this.listOfProduct = '';
    let toolName = this.SelectedTool == 'actionitem' ? 'trac-it' : this.SelectedTool;
    if (this.routerService.getPreviousUrl().includes('admin/' + toolName + '/subscriber/manage')) {
      this.setSaveFilter();
    }
    else {
      this.clearSession();
    }

    this.dropdownSettingsStatus = {
      singleSelection: true,
      text: "Filter by Active",
      enableSearchFilter: false,
      classes: "borderLessTextBoxGreen",
      badgeShowLimit: 3,
      noDataLabel: 'No record available',
      showCheckbox: false
    };
    
    this.GetListOfCompany();
    this.setDropDownSetting();
    this.GetProductData();
  }

  GetListOfCompany() {
    this.isloading = true;
    this.companyService.GetCompanies(this.tokenKey, this.PageNumber, this.PageSize, this.WhereCondition, this.OrderBy, this.keywordFilter, this.loginUserId, this.listOfProduct, this.statusId).subscribe(
      data => {
        this.isloading = false;
        this.listOfCompany = data['companies'];
        if (this.listOfCompany != null) {
          if (this.listOfCompany.length > 0) {
            this.totalRecordCount = data['companies'][0].totalRecordCount;
          }
          else {
            this.totalRecordCount = 0;
          }
        }
      },
      error => {
        this.statusCode = error
        this.isloading = false;
      }
    );
  }

  // GetListOfCompany() {
  //   this.isloading = true;
  //   this.companyService.GetCompanies(this.tokenKey, this.PageNumber, this.PageSize, this.WhereCondition, this.OrderBy, this.keywordFilter, this.loginUserId, this.listOfProduct, this.statusId).subscribe(
  //     data => {
  //       this.isloading = false;
  //       this.listOfCompany =[];
  //       let lstcompanyF=data.data;
  //       if (lstcompanyF.length > 0) {

  //         this.totalRecordCount = lstcompanyF[0].totalRecordCount;

  //         for (let i = 0; i < lstcompanyF.length; i++) {
  //           let listOfProducts = JSON.parse(lstcompanyF[i].listOfProducts);
            
  //           this.listOfCompany.push({
  //             "clientCompanyId": lstcompanyF[i].clientCompanyId,
  //             "clientInformationId": lstcompanyF[i].clientInformationId,
  //             "companyId": lstcompanyF[i].companyId,
  //             "companyName": lstcompanyF[i].companyName,
  //             "createdBy":lstcompanyF[i].createdBy,
  //             "createdDate":lstcompanyF[i].createdDate,
  //             "dba": lstcompanyF[i].dba,
  //             "isActive": lstcompanyF[i].isActive,
  //             "isAffiliate": lstcompanyF[i].isAffiliate,
  //             "isChild": lstcompanyF[i].isChild,
  //             "isParent": lstcompanyF[i].isParent,
  //             "listOfProducts": listOfProducts,
  //             "modifiedBy": lstcompanyF[i].modifiedBy,
  //             "modifiedDate": lstcompanyF[i].modifiedDate,
  //             "msaStatus":lstcompanyF[i].msaStatus,
  //             "notes": lstcompanyF[i].notes,
  //             "parentCompanyId":lstcompanyF[i].parentCompanyId,
  //             "priceQuoteId":lstcompanyF[i].priceQuoteId,
  //             "primaryCompanyId":lstcompanyF[i].primaryCompanyId,
  //             "sfAccount":lstcompanyF[i].sfAccount,
  //             "totalRecordCount":lstcompanyF[i].totalRecordCount,
  //             "typeOfCompany":lstcompanyF[i].typeOfCompany,
  //             "userCount":lstcompanyF[i].userCount
  //           })
  //         }
  //       }
  //       else{
  //         this.totalRecordCount = 0;
  //       }

  //       // this.lstcompany = data.data;
  //       // if (this.lstcompany != null) {
  //       //   if (this.lstcompany.length > 0) {
  //       //     this.totalRecordCount = data.data[0].totalRecordCount;
  //       //   }
  //       //   else {
  //       //     this.totalRecordCount = 0;
  //       //   }
  //       // }
  //     },
  //     error => {
  //       this.statusCode = error
  //       this.isloading = false;
  //     }
  //   );
  // }

  GetProductData(): void {
    this.tokenKey = sessionStorage.getItem('tokenValue');

    this.productsService.GetProductList(this.tokenKey, this.loginUserId, false, true).subscribe(
      data => {
        if (data['product'] != null) {
          // Set All Item          
          for (let iCounter = 0; iCounter < data['product'].length; iCounter++) {
            this.dropdownListProduct.push(
              { 'id': data['product'][iCounter].productId, 'itemName': data['product'][iCounter].displayName }
            );
          }

          if (this.listOfProductID != null && this.listOfProductID != undefined) {
            for (let iCounter = 0; iCounter < this.listOfProductID.length; iCounter++) {
              if (this.listOfProductID[iCounter] != '') {
                let obj = data["product"].filter(m => m.productId == this.listOfProductID[iCounter]);
                if (obj != null) {
                  this.selectedItemsProduct.push({ "id": obj[0].productId, "itemName": obj[0].displayName });
                }
              }
            }
          }
        }
        else {
          this.showMessage(data['message'], 'Error', 'error');
        }
        this.dropdownSettingsProduct = {
          singleSelection: false,
          text: 'Filter by Product(s)',
          enableSearchFilter: false,
          classes: 'borderLessTextBoxGreen',
          badgeShowLimit: 1,
          noDataLabel: 'No product available',
          showCheckbox: true,
          enableFilterSelectAll: false
        };
      }, error => {
        this.showMessage(error, 'Error', 'error');
      })
  }
  sort(sortBy: any) {
    if (!this.isAscending) {
      this.isAscending = true;
      this.OrderBy = sortBy + ' DESC';

      this.setDescCSS(sortBy);
    }
    else {
      this.isAscending = false;
      this.OrderBy = sortBy;

      this.setAscCSS(sortBy);
    }
    this.GetListOfCompany();
  }
  editCompany(companyId: any,companyName,clientInformationId) {
    this.saveSession(); // To Store All filtered and other data to restore once back.
    let toolName = this.SelectedTool == 'actionitem' ? 'trac-it' : this.SelectedTool;
    sessionStorage.setItem('ClientCompanyName',companyName);
    this.router.navigate(['admin/' + toolName + '/company/manage/' + companyId+'/'+clientInformationId]);
  }

  assignUser(companyId: any) {
    this.saveSession(); // To Store All filtered and other data to restore once back.
    let toolName = this.SelectedTool == 'actionitem' ? 'trac-it' : this.SelectedTool;
    this.router.navigate(['admin/' + toolName + '/company/assignuser/' + companyId]);
  }

  pageChanged($event: any) {
    this.PageNumber = parseInt($event.page);
    this.GetListOfCompany();
  }

  addCompany() {
    this.saveSession(); // To Store All filtered and other data to restore once back.
    let toolName = this.SelectedTool == 'actionitem' ? 'trac-it' : this.SelectedTool;
    this.router.navigate(['admin/' + toolName + '/company/manage']);
  }

  getSubscriptionState(companyId: any) {
    this.stateSubscription = null;
    this.stateServices.GetStateMasterFindByCompanyId(this.tokenKey, companyId).subscribe(
      data => {
        
        this.stateSubscription = {
          wirelessStates: data.wirelessStates,
          wirelessStatesCount: data.wirelessStates ? data.wirelessStates.length : 0,
          iprStates: data.iprStates,
          iprStatesCount: data.iprStates ? data.iprStates.length : 0,
          voIPStates: data.voIPStates,
          voIPStatesCount: data.voIPStates ? data.voIPStates.length : 0,
          actionStates: data.actionStates,
          actionStatesCount: data.actionStates ? data.actionStates.length : 0,
          productAccess: data.productAccess,
          briefingStates: data.briefingStates,
          briefingStatesCount: data.briefingStates ? data.briefingStates.length : 0,
          carrierCategory: data.carrierCategory,
          carrierCategoryCount: data.carrierCategory ? data.carrierCategory.filter(m => m.isSelected == true).length : 0,
          training: data.training,
          trainingCount: data.training ? data.training.filter(m => m.isSelected == true).length : 0,
        };

        if (data.productAccess.length > 0) {
          let sub = data.productAccess.filter(m => m.productId !== ToolEnum.actionitem && m.productId !== ToolEnum.ipr && m.productId !== ToolEnum.voip && m.productId !== ToolEnum.wireless)
          if (sub.length > 0) {
            this.isGeneralAlertSubscription = true;
          }
          else {
            if (this.statesTabs != null && typeof (this.statesTabs) != 'undefined') {
              this.statesTabs.tabs[0].active = true;
            }
            this.isGeneralAlertSubscription = false;
          }
          this.devideRowCount = data.productAccess.length / 2;
          this.devideRowCount = this.devideRowCount.toString().indexOf(".") != -1 ? this.devideRowCount + 1 : this.devideRowCount;
        }
      },
      error => { this.statusCode = error }
    );
  }

  keywordFilters($event) {
    this.PageNumber = 1;
    if ($event.target.value != "" && $event.target.value != null) { this.keywordFilter = $event.target.value; }
    else { this.keywordFilter = 'null'; }

    this.GetListOfCompany();
  }


  removeElemetninArray(array, element) {
    return array.filter(e => e !== element);
  }
  onSelect($event) {
    this.PageNumber = 1;
    if (this.listOfProductID != null) {
      if (this.listOfProductID.length > 0) {
        this.listOfProductID = this.removeElemetninArray(this.listOfProductID, $event.id)
        this.listOfProductID.push($event.id);
      }
      else {
        this.listOfProductID.push($event.id);
      }
    }

    this.listOfProduct = this.listOfProductID.join(',');
    this.GetListOfCompany();
  }

  onSelectAll($event) {
    this.PageNumber = 1;
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfProductID != null) {
        if (this.listOfProductID.length > 0) {
          this.listOfProductID = this.removeElemetninArray(this.listOfProductID, $event[iCounter].id)
          this.listOfProductID.push($event[iCounter].id);
        }
        else {
          this.listOfProductID.push($event[iCounter].id);
        }
      }
    }
    this.listOfProduct = this.listOfProductID.join(',');
    this.GetListOfCompany();
  }

  onDeSelectAll($event) {
    this.PageNumber = 1;
    this.listOfProductID = [];
    this.listOfProduct = '';
    this.GetListOfCompany();
  }

  onDeSelect($event) {
    this.PageNumber = 1;
    if (this.listOfProductID != null) {
      if (this.listOfProductID.length > 0) {
        this.listOfProductID = this.removeElemetninArray(this.listOfProductID, $event.id)
      }
    }
    this.listOfProduct = this.listOfProductID.join(',');
    this.GetListOfCompany();
  }

  showMessage(msg: string, msgTitle: string, msgType: string) {
    if (msgType == 'success') {
      setTimeout(() => this.toastr.success(msg, msgTitle));
    }
    else if (msgType == 'error') {
      setTimeout(() => this.toastr.error(msg, msgTitle));
    }
  }

  onSelectStatus($event) {
    this.listOfStatus = this.removeElemetninArray(this.listOfStatus, $event.id)
    this.listOfStatus.push($event.id);
    this.statusId = ' ';
    if (this.listOfStatus.length > 0) {
      this.statusId = $event.id;
    }
    this.GetListOfCompany();
  }

  onDeSelectStatus($event) {
    if (this.listOfStatus != null) {
      if (this.listOfStatus.length > 0) {
        this.listOfStatus = this.removeElemetninArray(this.listOfStatus, $event.id)
      }
    }
    this.statusId='';
    if (this.listOfStatus.length > 0) {
      this.statusId = $event.id;
    }
    this.GetListOfCompany();
  }

  onDeSelectAllStatus($event) {
    this.statusId='';
    this.listOfStatus = [];
    this.GetListOfCompany();
  }

  saveSession() {
    sessionStorage.setItem('saveFilterIsAscending', this.isAscending ? 'true' : 'false');
    sessionStorage.setItem('saveFilterPageNo', this.PageNumber.toString());
    sessionStorage.setItem('saveFilterOrderBy', this.OrderBy);
    sessionStorage.setItem('saveFilterWhereCondition', this.WhereCondition);
    sessionStorage.setItem('saveFilterKeywordValue', this.keywordFilter);
    sessionStorage.setItem('saveFilterProductID', JSON.stringify(this.listOfProductID));
    sessionStorage.setItem('saveFilterCompanylist', JSON.stringify(this.listOfCompany));
  }

  clearSession() {
    sessionStorage.removeItem('saveFilterIsAscending');
    sessionStorage.removeItem('saveFilterPageNo');
    sessionStorage.removeItem('saveFilterOrderBy');
    sessionStorage.removeItem('saveFilterWhereCondition');
    sessionStorage.removeItem('saveFilterKeywordValue');
    sessionStorage.removeItem('saveFilterProductID');
    sessionStorage.removeItem('saveFilterCompanylist');
  }

  setSaveFilter() {
    if (sessionStorage.getItem('saveFilterProductID') != null && sessionStorage.getItem('saveFilterProductID') != '' && sessionStorage.getItem('saveFilterProductID') != 'undefined') {
      this.listOfProductID = JSON.parse(sessionStorage.getItem('saveFilterProductID'));
      this.listOfProduct = this.listOfProductID.join(',');
    }
    if (sessionStorage.getItem('saveFilterCompanylist') != null && sessionStorage.getItem('saveFilterCompanylist') != '' && sessionStorage.getItem('saveFilterCompanylist') != 'undefined') {
      this.listOfCompany = JSON.parse(sessionStorage.getItem('saveFilterCompanylist'));
    }
    if (sessionStorage.getItem('saveFilterPageNo') != null && sessionStorage.getItem('saveFilterPageNo') != '' && sessionStorage.getItem('saveFilterPageNo') != 'undefined') {
      this.PageNumber = +sessionStorage.getItem('saveFilterPageNo');
    }

    if (sessionStorage.getItem('saveFilterOrderBy') != null && sessionStorage.getItem('saveFilterOrderBy') != '' && sessionStorage.getItem('saveFilterOrderBy') != 'undefined') {
      this.OrderBy = sessionStorage.getItem('saveFilterOrderBy');
    }

    if (sessionStorage.getItem('saveFilterWhereCondition') != null && sessionStorage.getItem('saveFilterWhereCondition') != '' && sessionStorage.getItem('saveFilterWhereCondition') != 'undefined') {
      this.WhereCondition = sessionStorage.getItem('saveFilterWhereCondition');
    }

    if (sessionStorage.getItem('saveFilterKeywordValue') != null && sessionStorage.getItem('saveFilterKeywordValue') != '' && sessionStorage.getItem('saveFilterKeywordValue') != 'undefined') {
      this.keywordFilter = sessionStorage.getItem('saveFilterKeywordValue');
    }

    if (sessionStorage.getItem('saveFilterIsAscending') != null && sessionStorage.getItem('saveFilterIsAscending') != '' && sessionStorage.getItem('saveFilterIsAscending') != 'undefined') {
      this.isAscending = sessionStorage.getItem('saveFilterIsAscending') == 'false' ? false : true;
      if (this.isAscending) {
        this.setDescCSS(this.OrderBy.replace('DESC', '').trim());
      } else {
        this.setAscCSS(this.OrderBy.replace('DESC', '').trim());
      }
    }
    this.clearSession();
  }

  setDescCSS(sortBy) {
    this.upDowCompanyCSS = '';
    this.upDowIsActiveCSS = '';
    this.upDowCreatedDateCSS = '';
    this.upDowModifiedDateCSS = '';
    this.upDowSFAccountCSS = '';
    this.upDowDBACSS = '';
    if (sortBy === "companyName") {
      this.upDowCompanyCSS = 'fa fa-arrow-down';
    }
    else if (sortBy === "isActive") {
      this.upDowIsActiveCSS = 'fa fa-arrow-down';
    }
    else if (sortBy === "createdDate") {
      this.upDowCreatedDateCSS = 'fa fa-arrow-down';
    }
    else if (sortBy === "modifiedDate") {
      this.upDowModifiedDateCSS = 'fa fa-arrow-down';
    }
    else if (sortBy === "sfAccount") {
      this.upDowSFAccountCSS = 'fa fa-arrow-down';
    }
    else if (sortBy === "dba") {
      this.upDowDBACSS = 'fa fa-arrow-down';
    }
  }

  setAscCSS(sortBy) {
    this.upDowCompanyCSS = '';
    this.upDowIsActiveCSS = '';
    this.upDowCreatedDateCSS = '';
    this.upDowModifiedDateCSS = '';
    this.upDowSFAccountCSS = '';
    this.upDowDBACSS = '';
    if (sortBy === "companyName") {
      this.upDowCompanyCSS = 'fa fa-arrow-up';
    }

    else if (sortBy === "isActive") {
      this.upDowIsActiveCSS = 'fa fa-arrow-up';
    }
    else if (sortBy === "createdDate") {
      this.upDowCreatedDateCSS = 'fa fa-arrow-up';
    }

    else if (sortBy === "modifiedDate") {
      this.upDowModifiedDateCSS = 'fa fa-arrow-up';
    }
    else if (sortBy === "sfAccount") {
      this.upDowSFAccountCSS = 'fa fa-arrow-up';
    }
    else if (sortBy === "dba") {
      this.upDowDBACSS = 'fa fa-arrow-up';
    }
  }

  setDropDownSetting() {
    this.dropdownSettingsProduct = {
      singleSelection: false,
      text: 'Filter by Product(s)',
      enableSearchFilter: false,
      classes: 'borderLessTextBoxGreen',
      badgeShowLimit: 1,
      noDataLabel: 'No product available',
      showCheckbox: true,
      enableFilterSelectAll: false,
      enableCheckAll: false
    };

  }

  setDeleteData(id) {
    this.deleteCompanyId = id;
  }

  deleteRecord() {
    this.companyService.DeleteCompany(this.tokenKey, this.deleteCompanyId).subscribe(
      data => {
        this.deleteCompanyId = 0;
        if (data != null) {
          if (data['result'] > -1) {
            this.GetListOfCompany();
            this.toastr.success('Record deleted successfully.', 'Delete')
          }
          else {
            this.toastr.error('You can not delete company as it used in subscriber.', 'Error')
          }
        }
      },
      error => {

      }
    );
  }

  ExportCompanies() {
    this.isloading = true;
    this.blockedDocument = true;
    this.isShowprogressSpinner = true;
    this.companyService.ExportCompanies(this.tokenKey, this.PageNumber, this.PageSize, this.WhereCondition, this.OrderBy, this.keywordFilter, this.loginUserId, this.listOfProduct,this.statusId).subscribe(
      data => {
        this.blockedDocument = false;
        this.isShowprogressSpinner = false;
        this.isloading = false;
        if (data != null) {
     
          if (data.status == 'Success') {            
            this.filterModel.docPath = data.responseNewFilename;
            this.filterModel.docType = 'Company Exports';
            this.i360service.DownloadDocument(this.tokenKey, this.filterModel).subscribe(
              fileData => {
 
                if (fileData != null) {
                  FileSaver.saveAs(fileData, 'CompanyExports.csv');
                }
              }, error => {
                console.log(error);
              }
            );
          }
          else {
            this.toastr.error(data.status, 'Error');
          }
        }
      },
      error => {
        this.blockedDocument = false;
        this.isShowprogressSpinner = false;
        this.statusCode = error
        this.isloading = false;
      }
    );
  }

  
  ExportCompaniesProducts() {
    this.isloading = true;
    this.blockedDocument = true;
    this.isShowprogressSpinner = true;
    this.companyService.ExportCompaniesProducts(this.tokenKey).subscribe(
      data => {
        this.blockedDocument = false;
        this.isShowprogressSpinner = false;
        this.isloading = false;
        if (data != null) {
     
          if (data.status == 'Success') {            
            this.filterModel.docPath = data.responseNewFilename;
            this.filterModel.docType = 'Company Exports';
            this.i360service.DownloadDocument(this.tokenKey, this.filterModel).subscribe(
              fileData => {
 
                if (fileData != null) {
                  FileSaver.saveAs(fileData, 'CompanyProductsExports.csv');
                }
              }, error => {
                console.log(error);
              }
            );
          }
          else {
            this.toastr.error(data.status, 'Error');
          }
        }
      },
      error => {
        this.blockedDocument = false;
        this.isShowprogressSpinner = false;
        this.statusCode = error
        this.isloading = false;
      }
    );
  }

  updatePricingQuotes(clientInformationId,companyName,companyId) {
  sessionStorage.setItem('UserLoginTool','Admin');
  sessionStorage.setItem('ClientCompanyName',companyName);
  this.adminLayout.SelectedTool='Admin';
  let navigation = '/admin/CompanyProfile/company-info/'+clientInformationId+'/'+companyId;
  this.router.navigate([navigation]);
}

ViewOnBoarding(clientInformationId,companyName) {
sessionStorage.setItem('UserLoginTool','PricingQuote');
sessionStorage.setItem('ClientCompanyName',companyName);
this.adminLayout.SelectedTool='PricingQuote';
let navigation = '/admin/PricingQuote/add-company-info/'+clientInformationId;
this.router.navigate([navigation]);
}

toggleMenu(index: number) {
  this.openMenuIndex = this.openMenuIndex === index ? -1 : index; // Toggle the menu
}

@HostListener('document:click', ['$event'])
onDocumentClick(event: MouseEvent) {
  const target = event.target as HTMLElement;
  if (!target.closest('.kebab-menu')) {
    this.openMenuIndex = null;
  }
}

}
