import { throwError as observableThrowError, Observable } from 'rxjs';
import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { FilterModel } from 'src/Models/filterModel';

@Injectable({
  providedIn: 'root'
})
export class TrfaAlertService {

  AppUrl: string = "";
  filterModel = new FilterModel();
  constructor(private _http: HttpClient, @Inject('BASE_URL') baseUrl: string) {
    this.setAPIUrl();
  }

  setAPIUrl() {
    if (sessionStorage.getItem("BaseURL") != null && sessionStorage.getItem("BaseURL") != "") {
      this.AppUrl = sessionStorage.getItem("BaseURL") || '';
    }
  }

  GetTrfaAlertsFindByPages(TokenKey: string, filterModel: any): Observable<any[]> {
    this.setAPIUrl();
    let fullAPIPath = this.AppUrl + 'api/TRFAUpdateMaster/GetTrfaAlertsFindByPages';
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.post<any>(fullAPIPath, filterModel, httpOptions).pipe(catchError(this.handleError));
  }

  GetTrfaAlertsByIds(TokenKey: string, updateId: any, pageNumber: any, pageSize: any, filterText: any, userId, orderBy: string): Observable<any[]> {
    this.setAPIUrl();
    let fullAPIPath = this.AppUrl + 'api/TRFAUpdateMaster/GetTrfaAlertsByIds/' + updateId + '/' + pageNumber + '/' + pageSize + '/' + filterText + '/' + userId + '/' + orderBy;
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.get<any>(fullAPIPath, httpOptions).pipe(catchError(this.handleError));
  }  

  GetTrfaAlertsByUser(TokenKey: string, updateId: any, pageNumber: any, pageSize: any, userId: any, orderBy: string): Observable<any[]> {
    this.setAPIUrl();
    let fullAPIPath = this.AppUrl + 'api/TRFAUpdateMaster/GetTrfaAlertsByUser/' + updateId + '/' + pageNumber + '/' + pageSize + '/' + userId + '/' + orderBy;
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.get<any>(fullAPIPath, httpOptions).pipe(catchError(this.handleError));
  }

  AddUpdateTrfaAlerts(TokenKey: string, addUpdateTrfaAlert: any): Observable<any[]> {
    this.setAPIUrl();
    let fullAPIPath = this.AppUrl + 'api/TRFAUpdateMaster/AddUpdateTrfaAlerts';
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.post<any>(fullAPIPath, addUpdateTrfaAlert, httpOptions).pipe(catchError(this.handleError));
  }

  notsentAlertmail(TokenKey: string, updateId: any,productId:any): Observable<any[]> {
    this.setAPIUrl();
    let fullAPIPath = this.AppUrl + 'api/TRFAUpdateMaster/notsentAlertmail/' + updateId + '/' + productId;
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.get<any>(fullAPIPath, httpOptions).pipe(catchError(this.handleError));
}

  Get911AlertsFindByPages(TokenKey: string, filterModel: any): Observable<any[]> {
    this.setAPIUrl();
    let fullAPIPath = this.AppUrl + 'api/main911/Get911AlertsFindByPages';
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.post<any>(fullAPIPath, filterModel, httpOptions).pipe(catchError(this.handleError));
  }

  Get911AlertsByIds(TokenKey: string, updateId: any, pageNumber: any, pageSize: any, filterText: any, userId, orderBy: string): Observable<any[]> {
    this.setAPIUrl();
    let fullAPIPath = this.AppUrl + 'api/main911/Get911AlertsByIds/' + updateId + '/' + pageNumber + '/' + pageSize + '/' + filterText + '/' + userId + '/' + orderBy;
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.get<any>(fullAPIPath, httpOptions).pipe(catchError(this.handleError));
  }  

  Get911AlertsByUser(TokenKey: string, updateId: any, pageNumber: any, pageSize: any, userId: any, orderBy: string): Observable<any[]> {
    this.setAPIUrl();
    let fullAPIPath = this.AppUrl + 'api/main911/Get911AlertsByUser/' + updateId + '/' + pageNumber + '/' + pageSize + '/' + userId + '/' + orderBy;
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.get<any>(fullAPIPath, httpOptions).pipe(catchError(this.handleError));
  }

  addUpdate911Alerts(TokenKey: string, addUpdate911Alert: any): Observable<any[]> {
    this.setAPIUrl();
    let fullAPIPath = this.AppUrl + 'api/main911/AddUpdate911Alerts';
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.post<any>(fullAPIPath, addUpdate911Alert, httpOptions).pipe(catchError(this.handleError));
  }

  GetVoipAlertsFindByPages(TokenKey: string, filterModel: any): Observable<any[]> {
    this.setAPIUrl();
    let fullAPIPath = this.AppUrl + 'api/Updates/GetVoipAlertsFindByPages';
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.post<any>(fullAPIPath, filterModel, httpOptions).pipe(catchError(this.handleError));
  }

  GetVoipAlertsByIds(TokenKey: string, updateId: any, pageNumber: any, pageSize: any, filterText: any, userId, orderBy: string,productId:any): Observable<any[]> {
    this.setAPIUrl();
    let fullAPIPath = this.AppUrl + 'api/Updates/GetVoipAlertsByIds/' + updateId + '/' + pageNumber + '/' + pageSize + '/' + filterText + '/' + userId + '/' + orderBy + '/' + productId;
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.get<any>(fullAPIPath, httpOptions).pipe(catchError(this.handleError));
  }

  GetVoipAlertsByUser(TokenKey: string, updateId: any, pageNumber: any, pageSize: any, userId: any, orderBy: string,productId:any): Observable<any[]> {
    this.setAPIUrl();
    let fullAPIPath = this.AppUrl + 'api/Updates/GetVoipAlertsByUser/' + updateId + '/' + pageNumber + '/' + pageSize + '/' + userId + '/' + orderBy + '/' + productId;
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.get<any>(fullAPIPath, httpOptions).pipe(catchError(this.handleError));
  }

  AddUpdateVoipAlerts(TokenKey: string, addUpdate911Alert: any): Observable<any[]> {
    this.setAPIUrl();
    let fullAPIPath = this.AppUrl + 'api/Updates/AddUpdateVoipAlerts';
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.post<any>(fullAPIPath, addUpdate911Alert, httpOptions).pipe(catchError(this.handleError));
  }

  GetWirelessAlertsFindByPages(TokenKey: string, filterModel: any): Observable<any[]> {
    this.setAPIUrl();
    let fullAPIPath = this.AppUrl + 'api/Updates/GetWirelessAlertsFindByPages';
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.post<any>(fullAPIPath, filterModel, httpOptions).pipe(catchError(this.handleError));
  }

  GetWirelessAlertsByIds(TokenKey: string, updateId: any, pageNumber: any, pageSize: any, filterText: any, userId, orderBy: string,productId:any): Observable<any[]> {
    this.setAPIUrl();
    let fullAPIPath = this.AppUrl + 'api/Updates/GetWirelessAlertsByIds/' + updateId + '/' + pageNumber + '/' + pageSize + '/' + filterText + '/' + userId + '/' + orderBy + '/' + productId
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.get<any>(fullAPIPath, httpOptions).pipe(catchError(this.handleError));
  }  

  GetWirelessAlertsByUser(TokenKey: string, updateId: any, pageNumber: any, pageSize: any, userId: any, orderBy: string,productId:any): Observable<any[]> {
    this.setAPIUrl();
    let fullAPIPath = this.AppUrl + 'api/Updates/GetWirelessAlertsByUser/' + updateId + '/' + pageNumber + '/' + pageSize + '/' + userId + '/' + orderBy + '/' + productId
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.get<any>(fullAPIPath, httpOptions).pipe(catchError(this.handleError));
  }

  AddUpdateWirelessAlerts(TokenKey: string, addUpdateTrfaAlert: any): Observable<any[]> {
    this.setAPIUrl();
    let fullAPIPath = this.AppUrl + 'api/Updates/AddUpdateWirelessAlerts';
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.post<any>(fullAPIPath, addUpdateTrfaAlert, httpOptions).pipe(catchError(this.handleError));
  }

  GetBriefingAlertsFindByPages(TokenKey: string, filterModel: any): Observable<any[]> {
    this.setAPIUrl();
    let fullAPIPath = this.AppUrl + 'api/Inteserra360/GetBriefingAlertsFindByPages';
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.post<any>(fullAPIPath, filterModel, httpOptions).pipe(catchError(this.handleError));
  }

  GetBriefingAlertsByIds(TokenKey: string, briefingId: any, pageNumber: any, pageSize: any, filterText: any, userId, orderBy: string): Observable<any[]> {
    this.setAPIUrl();
    let fullAPIPath = this.AppUrl + 'api/Inteserra360/GetBriefingAlertsByIds/' + briefingId + '/' + pageNumber + '/' + pageSize + '/' + filterText + '/' + userId + '/' + orderBy;
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.get<any>(fullAPIPath, httpOptions).pipe(catchError(this.handleError));
  }  

  GetBriefingAlertsByUsers(TokenKey: string, briefingId: any, pageNumber: any, pageSize: any, userId: any, orderBy: string): Observable<any[]> {
    this.setAPIUrl();
    let fullAPIPath = this.AppUrl + 'api/Inteserra360/GetBriefingAlertsByUsers/' + briefingId + '/' + pageNumber + '/' + pageSize + '/' + userId + '/' + orderBy;
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.get<any>(fullAPIPath, httpOptions).pipe(catchError(this.handleError));
  }

  addUpdateBriefingAlerts(TokenKey: string, addUpdateBriefingAlert: any): Observable<any[]> {
    this.setAPIUrl();
    let fullAPIPath = this.AppUrl + 'api/Inteserra360/addUpdateBriefingAlerts';
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.post<any>(fullAPIPath, addUpdateBriefingAlert, httpOptions).pipe(catchError(this.handleError));
  }

  GetIPRAlertsFindByPages(TokenKey: string, filterModel: any): Observable<any[]> {
    this.setAPIUrl();
    let fullAPIPath = this.AppUrl + 'api/IPRUpdates/GetIPRAlertsFindByPages';
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.post<any>(fullAPIPath, filterModel, httpOptions).pipe(catchError(this.handleError));
  }

  GetIPRAlertsByIds(TokenKey: string, updateId: any, pageNumber: any, pageSize: any, filterText: any, userId, orderBy: string): Observable<any[]> {
    this.setAPIUrl();
    let fullAPIPath = this.AppUrl + 'api/IPRUpdates/GetIPRAlertsByIds/' + updateId + '/' + pageNumber + '/' + pageSize + '/' + filterText + '/' + userId + '/' + orderBy;
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.get<any>(fullAPIPath, httpOptions).pipe(catchError(this.handleError));
  } 

  GetIPRAlertsByUsers(TokenKey: string, updateId: any, pageNumber: any, pageSize: any, userId: any, orderBy: string): Observable<any[]> {
    this.setAPIUrl();
    let fullAPIPath = this.AppUrl + 'api/IPRUpdates/GetIPRAlertsByUsers/' + updateId + '/' + pageNumber + '/' + pageSize + '/' + userId + '/' + orderBy;
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.get<any>(fullAPIPath, httpOptions).pipe(catchError(this.handleError));
  }

  addUpdateIPRAlerts(TokenKey: string, addUpdateIPRAlert: any): Observable<any[]> {
    this.setAPIUrl();
    let fullAPIPath = this.AppUrl + 'api/IPRUpdates/addUpdateIPRAlerts';
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.post<any>(fullAPIPath, addUpdateIPRAlert, httpOptions).pipe(catchError(this.handleError));
  }

  GetRMDAlertsFindByPages(TokenKey: string, filterModel: any): Observable<any[]> {
    this.setAPIUrl();
    let fullAPIPath = this.AppUrl + 'api/RMDUpdates/GetRMDAlertsFindByPages';
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.post<any>(fullAPIPath, filterModel, httpOptions).pipe(catchError(this.handleError));
  }

  GetRMDAlertsByIds(TokenKey: string, updateId: any, pageNumber: any, pageSize: any, filterText: any, userId, orderBy: string): Observable<any[]> {
    this.setAPIUrl();
    let fullAPIPath = this.AppUrl + 'api/RMDUpdates/GetRMDAlertsByIds/' + updateId + '/' + pageNumber + '/' + pageSize + '/' + filterText + '/' + userId + '/' + orderBy;
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.get<any>(fullAPIPath, httpOptions).pipe(catchError(this.handleError));
  } 

  GetRMDAlertsByUsers(TokenKey: string, updateId: any, pageNumber: any, pageSize: any, userId: any, orderBy: string): Observable<any[]> {
    this.setAPIUrl();
    let fullAPIPath = this.AppUrl + 'api/RMDUpdates/GetRMDAlertsByUsers/' + updateId + '/' + pageNumber + '/' + pageSize + '/' + userId + '/' + orderBy;
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.get<any>(fullAPIPath, httpOptions).pipe(catchError(this.handleError));
  }

  addUpdateRMDAlerts(TokenKey: string, addUpdateRMDAlert: any): Observable<any[]> {
    this.setAPIUrl();
    let fullAPIPath = this.AppUrl + 'api/RMDUpdates/addUpdateRMDAlerts';
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.post<any>(fullAPIPath, addUpdateRMDAlert, httpOptions).pipe(catchError(this.handleError));
  }

  GetCrraAlertsFindByPages(TokenKey: string, filterModel: any): Observable<any[]> {
    this.setAPIUrl();
    let fullAPIPath = this.AppUrl + 'api/CRRAUpdateMaster/GetCrraAlertsFindByPages';
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.post<any>(fullAPIPath, filterModel, httpOptions).pipe(catchError(this.handleError));
  }

  GetCrraAlertsByIds(TokenKey: string, updateId: any, pageNumber: any, pageSize: any, filterText: any, userId, orderBy: string): Observable<any[]> {
    this.setAPIUrl();
    let fullAPIPath = this.AppUrl + 'api/CRRAUpdateMaster/GetCrraAlertsByIds/' + updateId + '/' + pageNumber + '/' + pageSize + '/' + filterText + '/' + userId + '/' + orderBy;
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.get<any>(fullAPIPath, httpOptions).pipe(catchError(this.handleError));
  }  

  GetCrraAlertsByUser(TokenKey: string, updateId: any, pageNumber: any, pageSize: any, userId: any, orderBy: string): Observable<any[]> {
    this.setAPIUrl();
    let fullAPIPath = this.AppUrl + 'api/CRRAUpdateMaster/GetCrraAlertsByUser/' + updateId + '/' + pageNumber + '/' + pageSize + '/' + userId + '/' + orderBy;
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.get<any>(fullAPIPath, httpOptions).pipe(catchError(this.handleError));
  }

  AddUpdateCrraAlerts(TokenKey: string, addUpdateTrfaAlert: any): Observable<any[]> {
    this.setAPIUrl();
    let fullAPIPath = this.AppUrl + 'api/CRRAUpdateMaster/AddUpdateCrraAlerts';
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.post<any>(fullAPIPath, addUpdateTrfaAlert, httpOptions).pipe(catchError(this.handleError));
  }

  GetIPCSAlertsFindByPages(TokenKey: string, filterModel: any): Observable<any[]> {
    this.setAPIUrl();
    let fullAPIPath = this.AppUrl + 'api/Updates/GetIPCSAlertsFindByPages';
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.post<any>(fullAPIPath, filterModel, httpOptions).pipe(catchError(this.handleError));
  }

  GetIPCSAlertsByIds(TokenKey: string, updateId: any, pageNumber: any, pageSize: any, filterText: any, userId, orderBy: string): Observable<any[]> {
    this.setAPIUrl();
    let fullAPIPath = this.AppUrl + 'api/Updates/GetIPCSAlertsByIds/' + updateId + '/' + pageNumber + '/' + pageSize + '/' + filterText + '/' + userId + '/' + orderBy;
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.get<any>(fullAPIPath, httpOptions).pipe(catchError(this.handleError));
  }

  GetIPCSAlertsByUser(TokenKey: string, updateId: any, pageNumber: any, pageSize: any, userId: any, orderBy: string): Observable<any[]> {
    this.setAPIUrl();
    let fullAPIPath = this.AppUrl + 'api/Updates/GetIPCSAlertsByUser/' + updateId + '/' + pageNumber + '/' + pageSize + '/' + userId + '/' + orderBy;
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.get<any>(fullAPIPath, httpOptions).pipe(catchError(this.handleError));
  }

  AddUpdateIPCSAlerts(TokenKey: string, addUpdate911Alert: any): Observable<any[]> {
    this.setAPIUrl();
    let fullAPIPath = this.AppUrl + 'api/Updates/AddUpdateIPCSAlerts';
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.post<any>(fullAPIPath, addUpdate911Alert, httpOptions).pipe(catchError(this.handleError));
  }

  private handleError(error: Response | any) {
    console.error(error.message || error);
    return observableThrowError(error.status)
  }
}
