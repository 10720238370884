import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ToastrService } from 'ngx-toastr';
import { ReportService } from 'src/Services/report.service';
import { UpdatesService } from 'src/Services/updatesService';
import { CheckAuth } from 'src/Services/utility.service';
import * as Chart from 'chart.js';

@Component({
  selector: 'app-briefings-stats-report',
  templateUrl: './briefings-stats-report.component.html',
  styleUrls: ['./briefings-stats-report.component.css']
})
export class BriefingsStatsReportComponent implements OnInit {
  loginuserID: any;
  isloading = false;
  SelectedTool = '';
  tokenKey: string = '';

  isShowprogressSpinner: boolean = false;
  blockedDocument: boolean = false;

  monthYearData: any[] = [];
  displayedColumns: string[] = [];

  dropdownListYear = [];
  selectedItemsYear = [];
  dropdownSettingsYear = {};
  listOfYearIds = [];
  selectedFromYear: number = 0;

  dropdownListToYear = [];
  selectedItemsToYear = [];
  dropdownSettingsToYear = {};
  selectedToYear: number = 0;

  public barChartLabels: string[] = [];
  yearColors = {};
  monthYearDataForChart: any[] = [];

  constructor(private checkAuth: CheckAuth, private titleService: Title,
    private reportService: ReportService, private updatesService: UpdatesService,
    private toastrService: ToastrService) {
    this.tokenExpireCalculation(sessionStorage.getItem('appStartTime'), sessionStorage.getItem('tokenExpiryValue'));
    this.loginuserID = +sessionStorage.getItem('AdminUserId');
    this.tokenKey = sessionStorage.getItem('tokenValue');
    this.titleService.setTitle('Briefings Stats Report');
    this.SelectedTool = sessionStorage.getItem('UserLoginTool');

    this.dropdownSettingsYear = {
      singleSelection: true,
      text: "Select From Year",
      enableSearchFilter: true,
      classes: "borderLessTextBoxGreen",
      badgeShowLimit: 3,
      noDataLabel: 'No Year available',
      showCheckbox: false,
      enableFilterSelectAll: false
    };

    this.dropdownSettingsToYear = {
      singleSelection: true,
      text: "Select To Year",
      enableSearchFilter: true,
      classes: "borderLessTextBoxGreen",
      badgeShowLimit: 3,
      noDataLabel: 'No Year available',
      showCheckbox: false,
      enableFilterSelectAll: false
    };

    this.GetYearFromBriefingMaster();
  }

  ngOnInit() {
    // this.GetBriefingStatesReport();
  }

  GetYearFromBriefingMaster() {
    this.isloading = true;
    this.isShowprogressSpinner = true;
    this.blockedDocument = true;
    this.reportService.GetYearFromBriefingMaster(this.tokenKey).subscribe(data => {
      this.isloading = false;
      this.isShowprogressSpinner = false;
      this.blockedDocument = false;
      if (data) {
        if (data.status == "Success") {
          for (let iCounter = 0; iCounter < data["yearList"].length; iCounter++) {
            this.dropdownListYear.push(
              { "id": data["yearList"][iCounter].year, "itemName": data["yearList"][iCounter].year }
            );
            this.dropdownListToYear.push(
              { "id": data["yearList"][iCounter].year, "itemName": data["yearList"][iCounter].year }
            );
          }
        }
      }
    })
  }

  showMessage(msg: string, msgTitle: string, msgType: string) {
    if (msgType == 'success') {
      setTimeout(() => this.toastrService.success(msg, msgTitle));
    }
    else if (msgType == 'error') {
      setTimeout(() => this.toastrService.error(msg, msgTitle));
    }
  }

  private myChart: Chart | null = null;

  GetBriefingStatesReport() {

    if (this.selectedFromYear == 0) {
      this.showMessage("Please select From Year", 'Error', 'error');
      return;
    }
    if (this.selectedToYear == 0) {
      this.showMessage("Please select To Year", 'Error', 'error');
      return;
    }

    if (this.selectedFromYear > this.selectedToYear) {
      this.showMessage("Please select To Year greater than From Year", 'Error', 'error');
      return;
    }

    this.isloading = true;
    this.isShowprogressSpinner = true;
    this.blockedDocument = true;
    this.monthYearData=[];
    this.monthYearDataForChart=[];
    this.reportService.GetBriefingStats(this.tokenKey, this.selectedFromYear, this.selectedToYear).subscribe(data => {
      this.isloading = false;
      this.isShowprogressSpinner = false;
      this.blockedDocument = false;
      if (data) {
        if (data.status == "Success") {
          this.monthYearData = data["yearList"];
          this.monthYearDataForChart= data["yearList"];
          if (data["yearList"].length > 0) {
            this.displayedColumns = Object.keys(data["yearList"][0]);

            const monthIndex = this.displayedColumns.indexOf('Month');
            if (monthIndex > -1) {
              this.displayedColumns.splice(monthIndex, 1);
              this.displayedColumns.unshift('Month');
            }
          }

          const ctx = document.getElementById('myChart') as HTMLCanvasElement;

          // Destroy existing chart instance if it exists
          if (this.myChart) {
            this.myChart.destroy();
          }


          const colors = [
            '#FF6384', // Red
            '#36A2EB', // Blue
            '#FFCE56', // Yellow
            '#4BC0C0', // Teal
            '#9966FF', // Purple
            '#FF9F40', // Orange
            '#E7E9ED', // Light Gray
            '#76C7C0', // Light Green
            '#F45B5B', // Pink
            '#1D3557', // Dark Blue
            '#2A9D8F', // Turquoise
            '#E76F51', // Burnt Orange
            '#8E44AD', // Dark Purple
            '#3498DB', // Bright Blue
            '#2ECC71', // Bright Green
            '#E74C3C', // Bright Red
            '#F1C40F', // Bright Yellow
            '#34495E', // Dark Gray-Blue
            '#9B59B6', // Light Purple
            '#27AE60', // Forest Green
            '#F39C12', // Amber
            '#D35400', // Brick Orange
            '#BDC3C7', // Light Silver
            '#7F8C8D', // Dark Gray
            '#16A085'  // Aqua Green
          ];

          // this.monthYearDataForChart.splice(12, 1);
          const filteredYearList = this.monthYearDataForChart.filter(item => item.Month !== 'Annual');

          const labels = filteredYearList.map(item => item.Month);

          const years = Object.keys(filteredYearList[0]).filter(key => key !== 'Month');
          // const datasets = years.map((year, index) => {
          //   return {
          //     label: year,
          //     data: filteredYearList.map(item => item[year]),
          //     backgroundColor: colors[index % colors.length],
          //     borderWidth: 1
          //   };
          // });

          const datasets = years.map((year, index) => {
            return {
              label: year,
              data: filteredYearList.map(item => item[year]),
              backgroundColor: colors[index % colors.length], // Cycle through the colors array
              borderWidth: 1
            };
          });

          this.myChart = new Chart(ctx, {
            type: 'bar',
            data: {
              labels: labels,
              datasets: datasets
            },
            options: {
              scales: {
                yAxes: [{
                  ticks: {
                    beginAtZero: true
                  }
                }]
              },
              tooltips: {
                callbacks: {
                  // Modify the label inside the tooltip
                  label: function(tooltipItem, data) {
                    const datasetLabel = data.datasets[tooltipItem.datasetIndex].label;
                    const value = tooltipItem.yLabel;  // Get the value
                    return datasetLabel +': $' + value;  // Add the $ sign to the value
                  }
                }
              },
              plugins: {
                datalabels: {
                  anchor: 'end',
                  align: 'top',
                  color: '#000',
                  font: {
                    weight: 'bold'
                  },
                  formatter: function(value, context) {
                    return '$' + value;  
                  }
                }
              }
            }
          });

        }
      }
    })
  }

  tokenExpireCalculation(startDate: string, tokenExpiry: string) {
    this.checkAuth.tokenExpireCalculation(startDate, tokenExpiry);
  }

  onSelectYear($event) {
    this.selectedFromYear = $event.id;
  }

  onDeSelectYear($event) {
    this.selectedFromYear = 0;
  }

  onDeSelectAllYear($event) {
    this.selectedFromYear = 0;
  }

  onSelectToYear($event) {
    this.selectedToYear = $event.id;
  }

  onDeSelectToYear($event) {
    this.selectedToYear = 0;
  }

  onDeSelectAllToYear($event) {
    this.selectedToYear = 0;
  }

  getColorForYear(year: string): string {
    if (this.yearColors[year]) {
      return this.yearColors[year];
    }

    const color = this.getRandomColor();
    this.yearColors[year] = color;
    return color;
  }

  getRandomColor() {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

  getPredefinedColors(): string[] {
    return ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF', '#FF9F40', '#C9CBCF'];
  }
}
