import { Component, OnInit, ViewChild, Inject, HostListener } from '@angular/core';
import { CheckAuth } from 'src/Services/utility.service';
import { UserModel } from '../../../Models/usersModel';
import { UsersService } from '../../../Services/users.service';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { StateMasterService } from 'src/Services/state-master.service';
import { TabsetComponent } from 'ngx-bootstrap';
import { ToolEnum, AdminUserType } from 'src/Models/Common';
import { RouterExtService } from 'src/Services/RouterExtService';
import { ProductsService } from 'src/Services/products.service';
import { ToastrService } from 'ngx-toastr';
import { ClientCompanyModel } from 'src/Models/ClientCompanyModel';
import { UpdatesService } from 'src/Services/updatesService';
import { ProductModel } from 'src/Models/ProductModel';
import { UserPemissionService } from 'src/Services/userpemission.service';
import { UserPermissionModal } from 'src/Models/userPermissionModel';
import { FilterModel } from 'src/Models/filterModel';
import { I360Service } from 'src/Services/i360Service';
import * as FileSaver from 'file-saver';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css']
})
export class UsersComponent implements OnInit {
  @ViewChild('statesTabs', { static: false }) statesTabs: TabsetComponent;

  tokenKey: string = '';
  listOfUser: UserModel[];
  statusCode: any;
  PageNumber: number;
  PageSize: number;
  WhereCondition: string;
  OrderBy: string;
  totalRecordCount: number;

  isAscending: boolean;

  upDowUserNameCSS: string = '';
  upDowFirstNameCSS: string = '';
  upDowEmailCSS: string = '';
  upDowCompanyNameCSS: string = '';
  upDowPhoneCSS: string = '';
  upDowIsActiveCSS: string = '';
  upDowCreatedDateCSS: string = '';
  upDowStateNameCSS: string = '';
  upDowModifiedDateCSS: string = '';
  isloading: boolean = false;
  keywordFilter: string = '';
  SelectedTool: string;
  stateSubscription: any;

  isGeneralAlertSubscription: boolean = false;
  loginUserId: number = 0;

  isUserSystemAdmin: boolean = false;

  // Product List---------------
  dropdownListProduct = [];
  selectedItemsProduct = [];
  dropdownSettingsProduct = {};
  productFilter: string = '';
  listOfProductID: string[] = [];
  // Product List---------------
  listOfProduct?: string;

  clientCompanyList: ClientCompanyModel[];
  dropdownListclientCompany = [];
  selectedItemsclientCompany = [];
  dropdownSettingclientCompany = {};
  listOfCompany = [];

  productId: number = 1;
  systemUserCondition: string = '';

  // Online Register
  //clientOnlineRegister: ClientCompanyModel[];
  dropdownOnlineRegister = [];
  selectedItemsOnlineRegister = [];
  dropdownSettingOnlineRegister = {};
  listOfOnlineRegister = [];
  userPermissionModal: UserPermissionModal[];
  permissionUser: any;
  userId = 0;
  isShowprogressSpinner = false;
  blockedDocument = false;
  deleteUserId = 0;
  @ViewChild('modelCancelButton', { static: true }) modelCancelButton;

  filterModel = new FilterModel();
  dropdownSettingsStatus = {};
  selectedItemsStatus = [];
  listOfStatus = [];
  statusId: string = ' ';
  moduleList: any[] = [{ itemName: 'Yes', id: '1' }, { itemName: 'No', id: '0' }];

  duplicateUserId: number = 0;
  openMenuIndex: number = -1

  constructor(@Inject(Window) private win: Window, private router: Router, private checkAuth: CheckAuth, private userService: UsersService
    , private titleService: Title, private stateServices: StateMasterService
    , private routerService: RouterExtService
    , private productsService: ProductsService
    , private toastr: ToastrService
    , private userPermissionService: UserPemissionService
    , private i360service: I360Service
    , private updatesService: UpdatesService) {
    this.tokenExpireCalculation(sessionStorage.getItem('appStartTime'), sessionStorage.getItem('tokenExpiryValue'));
    this.tokenKey = sessionStorage.getItem('tokenValue');

    this.listOfUser = [];
    this.PageNumber = 1;
    this.PageSize = 10;
    this.WhereCondition = 'null';
    this.OrderBy = 'UserId DESC';
    this.totalRecordCount = -1;
    this.isAscending = true;
    this.selectedItemsStatus.push({ "id": 1, "itemName": "Yes" })
    this.listOfStatus.push(1);
    this.statusId = ' ';
    if (this.listOfStatus.length > 0) {
      this.statusId = '1'
    }
    this.SelectedTool = sessionStorage.getItem('UserLoginTool');
    this.titleService.setTitle('Subscribers');
    this.loginUserId = +sessionStorage.getItem('AdminUserId');
    this.productId = ToolEnum[this.SelectedTool];
    if (AdminUserType.systemadmin == parseInt(sessionStorage.getItem('adminTypeId')))
      this.systemUserCondition = 'null'
    else
      this.systemUserCondition = '  (CompanyId IN (Select CompanyId From [SystemUserCompany] Where [SystemUserCompany].UserId = ' + this.loginUserId + '))';
    this.setOnlineRegister();
  }

  setOnlineRegister() {
    this.dropdownOnlineRegister = [];
    this.dropdownOnlineRegister.push(
      { 'id': 1, 'itemName': 'Users with only free subscriptions' },
      { 'id': 0, 'itemName': 'Users with paid subscriptions' }
    );

    this.dropdownSettingOnlineRegister = {
      singleSelection: true,
      text: 'Filter by Unassign user product(s)',
      enableSearchFilter: false,
      classes: 'borderLessTextBoxGreen',
      badgeShowLimit: 1,
      noDataLabel: 'No unassign user product available',
      showCheckbox: false,
      enableFilterSelectAll: false
    };

    this.dropdownSettingsStatus = {
      singleSelection: true,
      text: "Filter by Active",
      enableSearchFilter: false,
      classes: "borderLessTextBoxGreen",
      badgeShowLimit: 3,
      noDataLabel: 'No record available',
      showCheckbox: false
    };
  }

  tokenExpireCalculation(startDate: string, tokenExpiry: string) {
    this.checkAuth.tokenExpireCalculation(startDate, tokenExpiry);
  }
  ngOnInit() {
    this.keywordFilter = 'null';
    if (AdminUserType.systemadmin == parseInt(sessionStorage.getItem('adminTypeId'))) {
      this.isUserSystemAdmin = true;
      this.WhereCondition = ' (UserTypeId = 3)';
    }
    else {
      this.WhereCondition = ' (UserTypeId = 3 And CompanyId IN (Select CompanyId From [SystemUserCompany] Where [SystemUserCompany].UserId = ' + this.loginUserId + '))';
    }
    this.listOfProduct = '';
    let toolName = this.SelectedTool == 'actionitem' ? 'trac-it' : this.SelectedTool;
    if (this.routerService.getPreviousUrl().includes('admin/' + toolName + '/subscriber/manage')) {
      this.setSaveFilter();
    }
    else {
      this.clearSession();
    }
    this.GetListOfUserV2();
    this.setDropDownSetting();
    this.GetProductData();
    this.GetClientCompany();
  }

  GetListOfUser() {
    this.isloading = true;
    let selectedcompany = this.listOfCompany.join(',');
    this.userService.GetUsers(this.tokenKey, this.PageNumber, this.PageSize, this.WhereCondition, this.OrderBy, this.keywordFilter, this.loginUserId, this.listOfProduct, selectedcompany, this.listOfOnlineRegister.join(','), this.statusId).subscribe(
      data => {
        this.isloading = false;
        this.listOfUser = data['usersModel'];
        if (this.listOfUser != null) {
          if (this.listOfUser.length > 0) {
            this.totalRecordCount = data['usersModel'][0].totalRecordCount;
          }
          else {
            this.totalRecordCount = 0;
          }
        }
      },
      error => {
        this.statusCode = error
        this.isloading = false;
      }
    );
  }

  GetListOfUserV2() {
    this.isloading = true;
    let selectedcompany = this.listOfCompany.join(',');
    this.userService.GetUsersV2(this.tokenKey, this.PageNumber, this.PageSize, this.WhereCondition, this.OrderBy, this.keywordFilter, this.loginUserId, this.listOfProduct, selectedcompany, this.listOfOnlineRegister.join(','), this.statusId).subscribe(
      data => {
        this.isloading = false;
        this.listOfUser = data['usersModel'];
        if (this.listOfUser != null) {
          if (this.listOfUser.length > 0) {
            this.totalRecordCount = data['usersModel'][0].totalRecordCount;
          }
          else {
            this.totalRecordCount = 0;
          }
        }
      },
      error => {
        this.statusCode = error
        this.isloading = false;
      }
    );
  }

  GetProductData(): void {
    this.tokenKey = sessionStorage.getItem('tokenValue');

    this.productsService.GetProductList(this.tokenKey, this.loginUserId, false, true).subscribe(
      data => {
        if (data['product'] != null) {
          // Set All Item          
          for (let iCounter = 0; iCounter < data['product'].length; iCounter++) {
            this.dropdownListProduct.push(
              { 'id': data['product'][iCounter].productId, 'itemName': data['product'][iCounter].displayName }
            );
          }

          if (this.listOfProductID != null && this.listOfProductID != undefined) {
            for (let iCounter = 0; iCounter < this.listOfProductID.length; iCounter++) {
              if (this.listOfProductID[iCounter] != '') {
                let obj = data["product"].filter(m => m.productId == this.listOfProductID[iCounter]);
                if (obj != null) {
                  this.selectedItemsProduct.push({ "id": obj[0].productId, "itemName": obj[0].displayName });
                }
              }
            }
          }
        }
        else {
          this.showMessage(data['message'], 'Error', 'error');
        }
        this.dropdownSettingsProduct = {
          singleSelection: false,
          text: 'Filter by Product(s)',
          enableSearchFilter: false,
          classes: 'borderLessTextBoxGreen',
          badgeShowLimit: 1,
          noDataLabel: 'No product available',
          showCheckbox: true,
          enableFilterSelectAll: false
        };
      }, error => {
        this.showMessage(error, 'Error', 'error');
      })
  }
  sort(sortBy: any) {
    if (!this.isAscending) {
      this.isAscending = true;
      this.OrderBy = sortBy + ' DESC';

      this.setDescCSS(sortBy);
    }
    else {
      this.isAscending = false;
      this.OrderBy = sortBy;

      this.setAscCSS(sortBy);
    }
    this.GetListOfUserV2();
  }
  editUser(userId: any) {
    this.saveSession(); // To Store All filtered and other data to restore once back.
    let toolName = this.SelectedTool == 'actionitem' ? 'trac-it' : this.SelectedTool;
    this.router.navigate(['admin/' + toolName + '/subscriber/manage/' + userId]);
  }

  pageChanged($event: any) {
    this.PageNumber = parseInt($event.page);
    this.GetListOfUserV2();
  }

  addUser() {
    this.saveSession(); // To Store All filtered and other data to restore once back.
    let toolName = this.SelectedTool == 'actionitem' ? 'trac-it' : this.SelectedTool;
    this.router.navigate(['admin/' + toolName + '/subscriber/manage']);
  }

  getSubscriptionState(clientId: any) {
    this.stateSubscription = null;
    this.stateServices.GetStateMasterFindByClientId(this.tokenKey, clientId, 0).subscribe(
      data => {
        this.stateSubscription = {
          wirelessStates: data.wirelessStates,
          wirelessStatesCount: data.wirelessStates ? data.wirelessStates.length : 0,
          iprStates: data.iprStates,
          iprStatesCount: data.iprStates ? data.iprStates.length : 0,
          voIPStates: data.voIPStates,
          voIPStatesCount: data.voIPStates ? data.voIPStates.length : 0,
          actionStates: data.actionStates,
          actionStatesCount: data.actionStates ? data.actionStates.length : 0,
          productAccess: data.productAccess,
          briefingStates: data.briefingStates,
          briefingStatesCount: data.briefingStates ? data.briefingStates.length : 0,
          carrierCategory: data.carrierCategory,
          carrierCategoryCount: data.carrierCategory ? data.carrierCategory.filter(m => m.isSelected == true).length : 0,
          training: data.training,
          trainingCount: data.training ? data.training.filter(m => m.isSelected == true).length : 0,
        };

        if (data.productAccess.length > 0) {
          let sub = data.productAccess.filter(m => m.productId !== ToolEnum.actionitem && m.productId !== ToolEnum.ipr && m.productId !== ToolEnum.voip && m.productId !== ToolEnum.wireless)
          if (sub.length > 0) {
            this.isGeneralAlertSubscription = true;
          }
          else {
            if (this.statesTabs != null && typeof (this.statesTabs) != 'undefined') {
              this.statesTabs.tabs[0].active = true;
            }
            this.isGeneralAlertSubscription = false;
          }
        }
      },
      error => { this.statusCode = error }
    );
  }

  keywordFilters($event) {
    this.PageNumber = 1;
    if ($event.target.value != "" && $event.target.value != null) { this.keywordFilter = $event.target.value; }
    else { this.keywordFilter = 'null'; }

    this.GetListOfUserV2();
  }

  redirectToClientLogin(userId, userLoginUrl) {
    const url = 'adminclientlogin/' + this.loginUserId + '/' + userId;
    userLoginUrl += userLoginUrl.indexOf("#") > -1 ? url : "#/" + url;
    this.win.open(userLoginUrl);
  }
  redirectToClientLogini360(userId, userLoginUrl) {
    const url = 'adminclientlogin/' + this.loginUserId + '/' + userId;
    userLoginUrl += userLoginUrl.indexOf("#") > -1 ? url : "#/" + url;
    this.win.open(userLoginUrl);
  }

  removeElemetninArray(array, element) {
    return array.filter(e => e !== element);
  }
  onSelect($event) {
    this.PageNumber = 1;
    if (this.listOfProductID != null) {
      if (this.listOfProductID.length > 0) {
        this.listOfProductID = this.removeElemetninArray(this.listOfProductID, $event.id)
        this.listOfProductID.push($event.id);
      }
      else {
        this.listOfProductID.push($event.id);
      }
    }

    this.listOfProduct = this.listOfProductID.join(',');
    this.GetListOfUserV2();
  }

  onSelectAll($event) {
    this.PageNumber = 1;
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfProductID != null) {
        if (this.listOfProductID.length > 0) {
          this.listOfProductID = this.removeElemetninArray(this.listOfProductID, $event[iCounter].id)
          this.listOfProductID.push($event[iCounter].id);
        }
        else {
          this.listOfProductID.push($event[iCounter].id);
        }
      }
    }
    this.listOfProduct = this.listOfProductID.join(',');
    this.GetListOfUserV2();
  }

  onDeSelectAll($event) {
    this.PageNumber = 1;
    this.listOfProductID = [];
    this.listOfProduct = '';
    this.GetListOfUserV2();
  }

  onDeSelect($event) {
    this.PageNumber = 1;
    if (this.listOfProductID != null) {
      if (this.listOfProductID.length > 0) {
        this.listOfProductID = this.removeElemetninArray(this.listOfProductID, $event.id)
      }
    }
    this.listOfProduct = this.listOfProductID.join(',');
    this.GetListOfUserV2();
  }

  onSelectStatus($event) {
    this.listOfStatus = this.removeElemetninArray(this.listOfStatus, $event.id)
    this.listOfStatus.push($event.id);
    this.statusId = ' ';
    if (this.listOfStatus.length > 0) {
      this.statusId = $event.id;
    }
    this.GetListOfUserV2();
  }

  onDeSelectStatus($event) {
    if (this.listOfStatus != null) {
      if (this.listOfStatus.length > 0) {
        this.listOfStatus = this.removeElemetninArray(this.listOfStatus, $event.id)
      }
    }
    this.statusId='';
    if (this.listOfStatus.length > 0) {
      this.statusId = $event.id;
    }
    this.GetListOfUserV2();
  }

  onDeSelectAllStatus($event) {
    this.statusId='';
    this.listOfStatus = [];
    this.GetListOfUserV2();
  }

  showMessage(msg: string, msgTitle: string, msgType: string) {
    if (msgType == 'success') {
      setTimeout(() => this.toastr.success(msg, msgTitle));
    }
    else if (msgType == 'error') {
      setTimeout(() => this.toastr.error(msg, msgTitle));
    }
    else if (msgType == 'warning') {
      setTimeout(() => this.toastr.warning(msg, msgTitle));
    }
  }
  
  saveSession() {
    sessionStorage.setItem('saveFilterIsAscending', this.isAscending ? 'true' : 'false');
    sessionStorage.setItem('saveFilterPageNo', this.PageNumber.toString());
    sessionStorage.setItem('saveFilterOrderBy', this.OrderBy);
    sessionStorage.setItem('saveFilterWhereCondition', this.WhereCondition);
    sessionStorage.setItem('saveFilterKeywordValue1', this.keywordFilter);
    sessionStorage.setItem('saveFilterProductID', JSON.stringify(this.listOfProductID));
    sessionStorage.setItem('saveFilterCompanylist', JSON.stringify(this.listOfCompany));
  }

  clearSession() {
    sessionStorage.removeItem('saveFilterIsAscending');
    sessionStorage.removeItem('saveFilterPageNo');
    sessionStorage.removeItem('saveFilterOrderBy');
    sessionStorage.removeItem('saveFilterWhereCondition');
    sessionStorage.removeItem('saveFilterKeywordValue');
    sessionStorage.removeItem('saveFilterProductID');
    sessionStorage.removeItem('saveFilterCompanylist');
  }

  setSaveFilter() {
    if (sessionStorage.getItem('saveFilterProductID') != null && sessionStorage.getItem('saveFilterProductID') != '' && sessionStorage.getItem('saveFilterProductID') != 'undefined') {
      this.listOfProductID = JSON.parse(sessionStorage.getItem('saveFilterProductID'));
      this.listOfProduct = this.listOfProductID.join(',');
    }
    if (sessionStorage.getItem('saveFilterCompanylist') != null && sessionStorage.getItem('saveFilterCompanylist') != '' && sessionStorage.getItem('saveFilterCompanylist') != 'undefined') {
      this.listOfCompany = JSON.parse(sessionStorage.getItem('saveFilterCompanylist'));
    }
    if (sessionStorage.getItem('saveFilterPageNo') != null && sessionStorage.getItem('saveFilterPageNo') != '' && sessionStorage.getItem('saveFilterPageNo') != 'undefined') {
      this.PageNumber = +sessionStorage.getItem('saveFilterPageNo');
    }

    if (sessionStorage.getItem('saveFilterOrderBy') != null && sessionStorage.getItem('saveFilterOrderBy') != '' && sessionStorage.getItem('saveFilterOrderBy') != 'undefined') {
      this.OrderBy = sessionStorage.getItem('saveFilterOrderBy');
    }

    if (sessionStorage.getItem('saveFilterWhereCondition') != null && sessionStorage.getItem('saveFilterWhereCondition') != '' && sessionStorage.getItem('saveFilterWhereCondition') != 'undefined') {
      this.WhereCondition = sessionStorage.getItem('saveFilterWhereCondition');
    }

    if (sessionStorage.getItem('saveFilterKeywordValue1') != null && sessionStorage.getItem('saveFilterKeywordValue1') != '' && sessionStorage.getItem('saveFilterKeywordValue1') != 'undefined') {
      this.keywordFilter = sessionStorage.getItem('saveFilterKeywordValue1');
    }

    if (sessionStorage.getItem('saveFilterIsAscending') != null && sessionStorage.getItem('saveFilterIsAscending') != '' && sessionStorage.getItem('saveFilterIsAscending') != 'undefined') {
      this.isAscending = sessionStorage.getItem('saveFilterIsAscending') == 'false' ? false : true;
      if (this.isAscending) {
        this.setDescCSS(this.OrderBy.replace('DESC', '').trim());
      } else {
        this.setAscCSS(this.OrderBy.replace('DESC', '').trim());
      }
    }
    this.clearSession();
  }

  setDescCSS(sortBy) {
    this.upDowUserNameCSS = '';
    this.upDowFirstNameCSS = '';
    this.upDowEmailCSS = '';
    this.upDowCompanyNameCSS = '';
    this.upDowPhoneCSS = '';
    this.upDowIsActiveCSS = '';
    this.upDowCreatedDateCSS = '';
    this.upDowStateNameCSS = '';
    this.upDowModifiedDateCSS = '';
    if (sortBy === "userName") {
      this.upDowUserNameCSS = 'fa fa-arrow-down';
    }
    else if (sortBy === "firstName") {
      this.upDowFirstNameCSS = 'fa fa-arrow-down';
    }
    else if (sortBy === "email") {
      this.upDowEmailCSS = 'fa fa-arrow-down';
    }
    else if (sortBy === "companyName") {
      this.upDowCompanyNameCSS = 'fa fa-arrow-down';
    }
    else if (sortBy === "phone") {
      this.upDowPhoneCSS = 'fa fa-arrow-down';
    }
    else if (sortBy === "isActive") {
      this.upDowIsActiveCSS = 'fa fa-arrow-down';
    }
    else if (sortBy === "createdDate") {
      this.upDowCreatedDateCSS = 'fa fa-arrow-down';
    }
    else if (sortBy === "StateName") {
      this.upDowStateNameCSS = 'fa fa-arrow-down';
    }
    else if (sortBy === "modifiedDate") {
      this.upDowModifiedDateCSS = 'fa fa-arrow-down';
    }
  }

  setAscCSS(sortBy) {
    this.upDowUserNameCSS = '';
    this.upDowFirstNameCSS = '';
    this.upDowEmailCSS = '';
    this.upDowCompanyNameCSS = '';
    this.upDowPhoneCSS = '';
    this.upDowIsActiveCSS = '';
    this.upDowCreatedDateCSS = '';
    this.upDowStateNameCSS = '';
    this.upDowModifiedDateCSS = '';

    if (sortBy === "userName") {
      this.upDowUserNameCSS = 'fa fa-arrow-up';
    }
    else if (sortBy === "firstName") {
      this.upDowFirstNameCSS = 'fa fa-arrow-up';
    }
    else if (sortBy === "email") {
      this.upDowEmailCSS = 'fa fa-arrow-up';
    }
    else if (sortBy === "companyName") {
      this.upDowCompanyNameCSS = 'fa fa-arrow-up';
    }
    else if (sortBy === "phone") {
      this.upDowPhoneCSS = 'fa fa-arrow-up';
    }
    else if (sortBy === "isActive") {
      this.upDowIsActiveCSS = 'fa fa-arrow-up';
    }
    else if (sortBy === "createdDate") {
      this.upDowCreatedDateCSS = 'fa fa-arrow-up';
    }
    else if (sortBy === "StateName") {
      this.upDowStateNameCSS = 'fa fa-arrow-up';
    }
    else if (sortBy === "modifiedDate") {
      this.upDowModifiedDateCSS = 'fa fa-arrow-up';
    }
  }

  setDropDownSetting() {
    this.dropdownSettingsProduct = {
      singleSelection: false,
      text: 'Filter by Product(s)',
      enableSearchFilter: false,
      classes: 'borderLessTextBoxGreen',
      badgeShowLimit: 1,
      noDataLabel: 'No product available',
      showCheckbox: true,
      enableFilterSelectAll: false,
      enableCheckAll: false
    };
    this.dropdownSettingclientCompany = {
      singleSelection: false,
      text: "Filter by Company(s)",
      enableSearchFilter: true,
      classes: "borderLessTextBoxGreen",
      badgeShowLimit: 1,
      noDataLabel: 'No company available',
      showCheckbox: true,
      enableFilterSelectAll: false,
      enableCheckAll: false
    };
  }
  GetClientCompany() {
    this.clientCompanyList = [];
    this.updatesService.GetClientCompany(this.tokenKey, 0, this.systemUserCondition).subscribe(
      data => {
        if (data['status'] == 'Success') {
          this.clientCompanyList = data['clientCompanay'];

          if (this.clientCompanyList != null) {
            if (this.clientCompanyList.length > 0) {
              for (let iCounter = 0; iCounter < this.clientCompanyList.length; iCounter++) {
                this.dropdownListclientCompany.push(
                  { "id": this.clientCompanyList[iCounter].companyId, "itemName": this.clientCompanyList[iCounter].companyName }
                );
              }
            }
          }

          if (this.listOfCompany != null && this.listOfCompany != undefined) {
            for (let iCounter = 0; iCounter < this.listOfCompany.length; iCounter++) {
              if (this.listOfCompany[iCounter] != '') {
                let obj = data["clientCompanay"].filter(m => m.companyId == this.listOfCompany[iCounter]);
                if (obj != null) {
                  this.selectedItemsclientCompany.push({ "id": obj[0].companyId, "itemName": obj[0].companyName });
                }
              }
            }
          }
        }
      },
      error => {
        this.showMessage(error, 'Error', 'error');
      }
    );
  }

  onSelectedCompany($event) {
    this.PageNumber = 1;
    let companyId = $event.id;
    if (this.listOfCompany != null) {
      if (this.listOfCompany.length > 0) {
        this.listOfCompany = this.removeElemetninArray(this.listOfCompany, $event.id)
        this.listOfCompany.push($event.id);
      }
      else {
        this.listOfCompany.push($event.id);
      }
    }
    this.GetListOfUserV2();
  }

  onDeSelectedCompany($event) {
    this.PageNumber = 1;
    if (this.listOfCompany != null) {
      if (this.listOfCompany.length > 0) {
        this.listOfCompany = this.removeElemetninArray(this.listOfCompany, $event.id)
      }
    }
    this.GetListOfUserV2();
  }

  onSelectAllCompany($event) {
    this.PageNumber = 1;
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfCompany != null) {
        if (this.listOfCompany.length > 0) {
          this.listOfCompany = this.removeElemetninArray(this.listOfCompany, $event[iCounter].id)
          this.listOfCompany.push($event[iCounter].id);
        }
        else {
          this.listOfCompany.push($event[iCounter].id);
        }
      }
    }
    this.GetListOfUserV2();
  }

  onDeSelectAllCompany($event) {
    this.PageNumber = 1;
    this.listOfCompany = [];
    this.GetListOfUserV2();
  }

  sendActivationEmail(userId) {
    this.blockDocument();
    this.userService.i360OnlineRegisterAccountActiveEmail(this.tokenKey, userId).subscribe(
      data => {
        this.unblockDocument();
        if (data != null) {
          if (data['isSuccess'] == true) {
            this.showMessage('Email sent successfully', 'Email Sent', 'success');
          }
          else if (data['isSuccess'] == false && data['status'] == 'Failure') {
            this.showMessage('Mail sent error', 'Error', 'error');
          }
          else
            this.showMessage('Cannot send emails to this user as "Do not email" is active for them.', 'Warning', 'warning');
        }
      },
      error => {
        console.log(error);
        this.unblockDocument();
      }
    );
  }

  onSelectedOnlineRegister($event) {
    this.listOfOnlineRegister = [];
    this.PageNumber = 1;
    if (this.listOfOnlineRegister != null) {
      if (this.listOfOnlineRegister.length > 0) {
        this.listOfOnlineRegister = this.removeElemetninArray(this.listOfOnlineRegister, $event.id)
        this.listOfOnlineRegister.push($event.id);
      }
      else {
        this.listOfOnlineRegister.push($event.id);
      }
    }
    this.GetListOfUserV2();
  }

  onDeSelectedOnlineRegister($event) {
    this.PageNumber = 1;
    if (this.listOfOnlineRegister != null) {
      if (this.listOfOnlineRegister.length > 0) {
        this.listOfOnlineRegister = this.removeElemetninArray(this.listOfOnlineRegister, $event.id)
      }
    }
    this.GetListOfUserV2();
  }

  onSelectAllOnlineRegister($event) {
    this.listOfOnlineRegister = [];
    this.PageNumber = 1;
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfOnlineRegister != null) {
        if (this.listOfOnlineRegister.length > 0) {
          this.listOfOnlineRegister = this.removeElemetninArray(this.listOfOnlineRegister, $event[iCounter].id)
          this.listOfOnlineRegister.push($event[iCounter].id);
        }
        else {
          this.listOfOnlineRegister.push($event[iCounter].id);
        }
      }
    }
    this.GetListOfUserV2();
  }

  onDeSelectAllOnlineRegister($event) {
    this.PageNumber = 1;
    this.listOfOnlineRegister = [];
    this.GetListOfUserV2();
  }

  showPermissionButton(listOfProducts: ProductModel[]) {
    if (listOfProducts && listOfProducts.length > 0) {
      return (listOfProducts.find(x => x.productId == 14) != null ||
        listOfProducts.find(x => x.productId == 15) != null ||
        listOfProducts.find(x => x.productId == 16) != null ||
        listOfProducts.find(x => x.productId == 18) != null)
    }
    return false;
  }


  showLoginButton(listOfProducts: ProductModel[]) {
    if (listOfProducts && listOfProducts.length > 0) {
      return (listOfProducts.find(x => x.productId == 1) != null ||
        listOfProducts.find(x => x.productId == 2) != null ||
        listOfProducts.find(x => x.productId == 3) != null ||
        listOfProducts.find(x => x.productId == 4) != null || listOfProducts.find(x => x.productId == 5) != null || listOfProducts.find(x => x.productId == 21) != null)
    }
    return false;
  }

  showi360LoginButton(listOfProducts: ProductModel[]) {
    if (listOfProducts && listOfProducts.length > 0) {
      return (listOfProducts.find(x => x.productId == 1) != null ||
        listOfProducts.find(x => x.productId == 4) != null ||
        listOfProducts.find(x => x.productId == 3) != null ||
        listOfProducts.find(x => x.productId == 7) != null || listOfProducts.find(x => x.productId == 8) != null ||
        listOfProducts.find(x => x.productId == 9) != null || listOfProducts.find(x => x.productId == 14) != null ||
        listOfProducts.find(x => x.productId == 22) != null || listOfProducts.find(x => x.productId == 23) != null || 
        listOfProducts.find(x => x.productId == 24) != null)
    }
    return false;
  }

  getUserPermission(user) {
    this.permissionUser = user;
    this.userPermissionModal = [];
    this.userPermissionService.i360UserPermissionByUserId(this.tokenKey, user.userId).subscribe(data => {
      if (data) {
        if (data.status == "Success") {
          if (data.userPermissions != null) {
            user.listOfProducts.filter(x => x.productId == 14 || x.productId == 15 || x.productId == 16 || x.productId == 18).forEach(element => {
              const temp = data.userPermissions.find(x => x.productId == element.productId);
              if (temp != null) {
                temp.productName = element.productName;
                this.userPermissionModal.push(temp);
              }
              else {
                this.userPermissionModal.push({
                  productName: element.productName,
                  addEdit: false,
                  view: false,
                  delete: false,
                  productId: element.productId,
                  userId: user.userId,
                  createdBy: this.loginUserId
                })
              }
            });
          }
        }
        else {
          this.showMessage(data.message, "Error", "error");
        }
      }
    })
  }

  saveUserPermission() {
    this.blockDocument();
    this.userPermissionService.i360UserPermissionInsert(this.tokenKey, this.userPermissionModal).subscribe(data => {
      this.unblockDocument();
      if (data) {
        if (data.status == "Success") {
          this.showMessage("Record inserted successfully.", "Inserted", "success");
          this.modelCancelButton.nativeElement.click();
        }
        else {
          this.showMessage(data.message, "Error", "error");
        }
      }
    })
  }

  setDeleteData(id) {
    this.deleteUserId = id;
  }

  deleteRecord() {
    this.userService.DeleteUser(this.tokenKey, this.deleteUserId).subscribe(
      data => {
        this.deleteUserId = 0;
        if (data != null) {
          if (data['result'] > -1) {
            this.GetListOfUserV2();
            this.toastr.success('Record deleted successfully.', 'Delete')
          }
          else {
            this.toastr.error('Something want wrong.', 'Error')
          }
        }
      },
      error => {

      }
    );
  }

  blockDocument() {
    this.isShowprogressSpinner = true;
    this.blockedDocument = true;
  }
  unblockDocument() {
    this.isShowprogressSpinner = false;
    this.blockedDocument = false;
  }

  ExportUsers() {
    this.isloading = true;
    this.blockedDocument = true;
    this.isShowprogressSpinner = true;
    let selectedcompany = this.listOfCompany.join(',');
    this.userService.ExportUsersV2(this.tokenKey, this.PageNumber, this.PageSize, this.WhereCondition, this.OrderBy, this.keywordFilter, this.loginUserId, this.listOfProduct, selectedcompany, this.listOfOnlineRegister.join(','),this.statusId).subscribe(
      data => {
        this.blockedDocument = false;
        this.isShowprogressSpinner = false;
        this.isloading = false;
        if (data != null) {

          if (data.status == 'Success') {            
            this.filterModel.docPath = data.responseNewFilename;
            this.filterModel.docType = 'User Exports';
            this.i360service.DownloadDocument(this.tokenKey, this.filterModel).subscribe(
              fileData => {
 
                if (fileData != null) {
                  FileSaver.saveAs(fileData, 'UserExports.csv');
                }
              }, error => {
                console.log(error);
              }
            );
          }
          else {
            this.toastr.error(data.status, 'Error');
          }
        }
      },
      error => {
        this.blockedDocument = false;
        this.isShowprogressSpinner = false;
        this.statusCode = error
        this.isloading = false;
      }
    );
  }

  duplicateUserData(userId) {
    sessionStorage.setItem('isDuplicate', 'true');
    this.router.navigate(['admin/Admin/subscriber/manage/' + userId])
  }

  setDuplicateId(userId) {
    this.duplicateUserId = userId;
  }

  toggleMenu(index: number) {
    this.openMenuIndex = this.openMenuIndex === index ? -1 : index; // Toggle the menu
  }
  
  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent) {
    const target = event.target as HTMLElement;
    if (!target.closest('.kebab-menu')) {
      this.openMenuIndex = null;
    }
  }

}
